export enum ColumnId {
  Id = 'id',
  Name = 'name',
  Note = 'note.text',
  MediaItemsCount = 'mediaItemsCount',
  CreatedAt = 'createdAt',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.Id]: 'ID',
  [ColumnId.Name]: 'Имя пользователя',
  [ColumnId.Note]: 'Заметка',
  [ColumnId.MediaItemsCount]: 'Медиаэлементы',
  [ColumnId.CreatedAt]: 'Создан',
};

export const columnsIds = [
  ColumnId.Id,
  ColumnId.Name,
  ColumnId.Note,
  ColumnId.MediaItemsCount,
  ColumnId.CreatedAt,
];
