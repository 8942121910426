import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { PlayerAdditionalData } from './PlayerAdditionalData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetPlayerAdditionalDataVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetPlayerAdditionalData = { __typename: 'Query', player: { __typename: 'Player', birthday?: string | null, city?: string | null, country?: string | null, createdAt: string, deletedAt?: string | null, email?: string | null, emailConfirmedAt?: string | null, firstName?: string | null, gender?: SchemaTypes.Gender | null, id: string, lastName?: string | null, lastVisited?: string | null, locale?: CommonType.Locale | null, middleName?: string | null, name: string, phone?: string | null, phoneConfirmedAt?: string | null, rank: CommonType.PlayerRank, referralCode?: string | null, state: SchemaTypes.PlayerState, twoFactorEnabled: boolean, affiliate?: { __typename: 'Company', id: string, name: string, companyEmail: string } | { __typename: 'Partner', id: string, name: string, partnerEmail?: string | null } | null, affiliateAccount?: { __typename: 'Company', id: string, name: string, companyEmail: string } | { __typename: 'Partner', id: string, name: string, partnerEmail?: string | null } | null } };


export const GetPlayerAdditionalDataDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPlayerAdditionalData"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"player"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PlayerAdditionalData"}}]}}]}},...PlayerAdditionalData.definitions]} as unknown as DocumentNode;

/**
 * __useGetPlayerAdditionalData__
 *
 * To run a query within a React component, call `useGetPlayerAdditionalData` and pass it any options that fit your needs.
 * When your component renders, `useGetPlayerAdditionalData` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlayerAdditionalData({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPlayerAdditionalData(baseOptions: ApolloReactHooks.QueryHookOptions<GetPlayerAdditionalData, GetPlayerAdditionalDataVariables> & ({ variables: GetPlayerAdditionalDataVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetPlayerAdditionalData, GetPlayerAdditionalDataVariables>(GetPlayerAdditionalDataDocument, options);
      }
export function useGetPlayerAdditionalDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPlayerAdditionalData, GetPlayerAdditionalDataVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetPlayerAdditionalData, GetPlayerAdditionalDataVariables>(GetPlayerAdditionalDataDocument, options);
        }
export function useGetPlayerAdditionalDataSuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<GetPlayerAdditionalData, GetPlayerAdditionalDataVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetPlayerAdditionalData, GetPlayerAdditionalDataVariables>(GetPlayerAdditionalDataDocument, options);
        }
export type GetPlayerAdditionalDataHookResult = ReturnType<typeof useGetPlayerAdditionalData>;
export type GetPlayerAdditionalDataLazyQueryHookResult = ReturnType<typeof useGetPlayerAdditionalDataLazyQuery>;
export type GetPlayerAdditionalDataSuspenseQueryHookResult = ReturnType<typeof useGetPlayerAdditionalDataSuspenseQuery>;
export type GetPlayerAdditionalDataQueryResult = Apollo.QueryResult<GetPlayerAdditionalData, GetPlayerAdditionalDataVariables>;