import { nbsp } from 'src/const';

export enum FieldName {
  Partner = 'partner',
  ActivationLimit = 'activationLimit',
  Token = 'token',
  ReferralLinkUrl = 'referralLinkUrl',
  ReferralLinkId = 'referralLinkId',
  MediaCampaignName = 'mediaCampaignName',
  MediaCampaignUrl = 'mediaCampaignUrl',
  Usage = 'usage',

  FreespinGameName = 'freespinGameName',
  FreespinGameId = 'freespinGameId',
  FreespinCurrency = 'freespinCurrency',
  FreespinCount = 'freespinCount',
  FreespinWager = 'freespinWager',
  FreespinMinBalance = 'freespinMinBalance',

  BonusAmount = 'bonusAmount',
  BonusCurrency = 'bonusCurrency',
  BonusWager = 'bonusWager',
  BonusCashout = 'bonusCashout',
  BonusMinBalance = 'bonusMinBalance',

  ConnectionWithFreespins = 'connectionWithFreespins',
  ConnectionWithBonus = 'connectionWithBonus',
  InvalidToken = 'invalidToken',
}

export const mapCreatePromoCodeFieldToTitle: {
  [key in FieldName]: string;
} = {
  [FieldName.Partner]: 'Партнер',
  [FieldName.ActivationLimit]: 'Количество активаций',
  [FieldName.Token]: 'Токен',
  [FieldName.ReferralLinkUrl]: 'Рефссылка',
  [FieldName.ReferralLinkId]: 'Рефссылка',
  [FieldName.MediaCampaignName]: 'Название медиакампании',
  [FieldName.MediaCampaignUrl]: 'URL медиакампании',
  [FieldName.Usage]: 'Использование',

  [FieldName.FreespinGameId]: 'Слот',
  [FieldName.FreespinCurrency]: 'Валюта',
  [FieldName.FreespinCount]: 'Количество',
  [FieldName.FreespinWager]: 'Вейджер',
  [FieldName.FreespinMinBalance]: 'Минимальный баланс',
  [FieldName.FreespinGameName]: 'Название игры',

  [FieldName.BonusAmount]: 'Сумма',
  [FieldName.BonusWager]: 'Вейджер',
  [FieldName.BonusCashout]: 'Кэшаут',
  [FieldName.BonusMinBalance]: 'Минимальный баланс',
  [FieldName.BonusCurrency]: 'Валюта',

  [FieldName.ConnectionWithFreespins]: `Связь с${nbsp}фриспинами`,
  [FieldName.ConnectionWithBonus]: `Связь с${nbsp}бонусом`,
  [FieldName.InvalidToken]: `${nbsp}`,
};

export const GO_TO_FIRST_STEP_BUTTON_INITIATOR_ID =
  'go-to-first-step-button-initiator-id';
