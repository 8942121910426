import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { addHashTagToId } from 'utils/addHashTagToId';
import { MediaItemPromoCodeModal } from '../components/MediaItemPromoCodeModal';

interface OpenModalParams {
  promoCodeId: string;
  initiatorId: string;
}

type UseOpenMediaItemPromoCodeModalResult = (params: OpenModalParams) => void;

export const useOpenMediaItemPromoCodeModal =
  (): UseOpenMediaItemPromoCodeModalResult => {
    const { dispatch: modalDispatch } = useContext(ModalContext);

    const openModal = useCallback(
      ({ promoCodeId, initiatorId }: OpenModalParams) => {
        modalDispatch({
          type: ModalActions.Open,
          payload: {
            title: addHashTagToId(promoCodeId),
            subTitle: 'Промокод',
            content: <MediaItemPromoCodeModal promoCodeId={promoCodeId} />,
            initiatorId,
          },
        });
      },
      [modalDispatch],
    );

    return openModal;
  };
