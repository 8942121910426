import { useCallback, useEffect, useMemo } from 'react';
import {
  Heights,
  getCountByWindowHeight,
} from 'src/utils/getCountByWindowHeight';
import { useGetTournamentRebuysLazyQuery } from '../queries/generated/GetTournamentRebuys';
import { useGetTournamentRebuysBlockLazyQuery } from '../queries/generated/GetTournamentRebuysBlock';

const COUNT_PER_PAGE = 10;

interface UseEventGroupsParams {
  isFullScreenTable: boolean;
  playerId?: string;
}
export const useGetTournamentRebuys = ({
  playerId,
  isFullScreenTable,
}: UseEventGroupsParams) => {
  const currentUseGetTournamentRebuys = isFullScreenTable
    ? useGetTournamentRebuysLazyQuery
    : useGetTournamentRebuysBlockLazyQuery;

  const [loadTournamentRebuys, { data, loading, error, fetchMore, refetch }] =
    currentUseGetTournamentRebuys();

  const countPerPage = isFullScreenTable
    ? getCountByWindowHeight(Heights.defaultCellHeight)
    : COUNT_PER_PAGE;

  const tournamentRebuysData = data?.player.tournamentRebuys;

  const tournamentRebuys = useMemo(
    () => tournamentRebuysData?.edges.map(({ node }) => node),
    [tournamentRebuysData],
  );

  const tournamentRebuysFetchMore = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: countPerPage,
            after: tournamentRebuysData?.pageInfo.endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [countPerPage, tournamentRebuysData?.pageInfo.endCursor, fetchMore],
  );

  useEffect(() => {
    if (!playerId) {
      return;
    }

    loadTournamentRebuys({
      variables: {
        first: countPerPage,
        playerId,
      },
    });
  }, [countPerPage, playerId, loadTournamentRebuys]);

  return {
    tournamentRebuysData,
    tournamentRebuys,
    tournamentRebuysLoading: loading,
    tournamentRebuysError: error,
    tournamentRebuysRefetch: refetch,
    tournamentRebuysFetchMore,
  };
};
