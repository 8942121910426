import { nbsp } from 'src/const';

export enum ColumnId {
  ExternalId = 'externalId',
  Currency = 'balance.currency',
  Balance = 'balance.amount',
  BlockedWithdrawalSum = 'blockedWithdrawalSum.amount',
  CreatedAt = 'createdAt',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.ExternalId]: 'Внешний ID',
  [ColumnId.Currency]: 'Валюта',
  [ColumnId.Balance]: 'Баланс',
  [ColumnId.BlockedWithdrawalSum]: `Сумма на${nbsp}вывод`,
  [ColumnId.CreatedAt]: 'Создан',
};

export const blockColumnsIds = [
  ColumnId.ExternalId,
  ColumnId.Currency,
  ColumnId.Balance,
  ColumnId.BlockedWithdrawalSum,
  ColumnId.CreatedAt,
];

export const pageColumnsIds = [
  ColumnId.ExternalId,
  ColumnId.Currency,
  ColumnId.Balance,
  ColumnId.BlockedWithdrawalSum,
  ColumnId.CreatedAt,
];
