import { FC } from 'react';
import { useParams } from 'react-router';
import { PlayerPageContainer } from '../../components/PlayerPageContainer';
import { AccountsTable } from '../../components/AccountsBlock/components/AccountsTable';
import { useGetPlayerAccountsColumns } from '../../components/AccountsBlock/components/AccountsTable/hooks/useGetPlayerAccountsColumns';

export const Accounts: FC = () => {
  const { columns } = useGetPlayerAccountsColumns(true);
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <PlayerPageContainer playerId={id}>
      <AccountsTable
        playerId={id}
        columns={columns}
        isFullScreenTable
        shouldReturnToPrevPage
      />
    </PlayerPageContainer>
  );
};
