import { useContext, useEffect } from 'react';
import { ModalActions, ModalContext, useModalManageData } from 'ui/Modal';
import { MediaCampaignModalSteps } from '../const';
import { useGetMediaCampaign as useGetMediaCampaignQuery } from '../queries/generated/GetMediaCampaign';

interface UseMediaItemModalDataParams {
  mediaCampaignId: string;
}

export const useMediaCampaignModal = ({
  mediaCampaignId,
}: UseMediaItemModalDataParams) => {
  const { state, dispatch: modalDispatch } = useContext(ModalContext);
  const { data, loading, error } = useGetMediaCampaignQuery({
    variables: {
      id: mediaCampaignId,
    },
  });

  useModalManageData({
    loading,
    errorConfig: { error, toastId: 'get-media-campaign-errors' },
    hasData: !!data,
  });

  useEffect(() => {
    if (state.step === MediaCampaignModalSteps.BaseStep) {
      modalDispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          title: `#${mediaCampaignId}`,
          subTitle: 'Медиакампания',
        },
      });

      return;
    }

    modalDispatch({
      type: ModalActions.UpdateModalProps,
      payload: {
        title: 'Редактировать медиакампанию',
        subTitle: `#${mediaCampaignId}`,
      },
    });
  }, [mediaCampaignId, modalDispatch, state.step]);

  const openEditMediaCampaignModal = () => {
    modalDispatch({
      type: ModalActions.UpdateModalProps,
      payload: {
        step: MediaCampaignModalSteps.EditNameStep,
      },
    });
  };

  return {
    mediaCampaign: data?.mediaCampaign,
    step: state.step,
    openEditMediaCampaignModal,
  };
};
