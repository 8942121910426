import { nbsp } from 'src/const';

export enum RewardModalSteps {
  BaseStep = 1,
  ConfirmationStep,
}

export const REWARD_SEARCH_PLACEHOLDER = `Найти вознаграждение по${nbsp}почте партнёра или компании`;
export const REV_SHARE_REWARD_INPUT_NAME = 'revShareRewardSearch';
export const CPA_REWARD_INPUT_NAME = 'cpaRewardSearch';

export const REWARD_BLOCK_TITLE = 'Вознаграждения';
export const REWARD_EMPTY_TEXT = 'Вознаграждений нет';
