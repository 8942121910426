import { FC, SyntheticEvent } from 'react';
import { TextOverflowMiddle } from 'ui/TextOverflowMiddle';
import { CardIcon, CardIconColor } from 'ui/Card/CardIcon';
import { faClone } from '@fortawesome/pro-solid-svg-icons';
import { toast } from 'react-toastify';
import Button from 'ui/Button';
import { copyToClipboard } from 'utils/copyToClipboard';
import styles from './CopyTableRow.module.scss';

interface Props {
  value: string;
  toastText: string;
}

export const CopyTableRow: FC<Props> = ({ value, toastText }) => {
  const handleClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    copyToClipboard(value);
    toast.success(toastText);
  };

  return (
    <Button linkStyled onClick={handleClick}>
      <div className={styles.buttonContent}>
        <div className={styles.referralLink}>
          <TextOverflowMiddle text={value} key={value} />
        </div>
        <CardIcon
          className={styles.iconContainer}
          config={{ icon: faClone, iconColor: CardIconColor.TRANSPARENT }}
        />
      </div>
    </Button>
  );
};
