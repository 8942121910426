import { FC, useState } from 'react';
import Block from 'ui/Block';
import { withBlockProvider } from 'ui/Block/store';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useParams } from 'react-router';
import { Route } from 'src/router/routes.const';
import { AffiliateTypeName, RewardTariffType } from 'commonTypes';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { STATISTIC_QUERY_PARAMS } from 'src/const';
import {
  CPA_COMPANY_PARTNERS_STATISTIC_BLOCK_TITLE,
  STATISTIC_NO_DATA,
} from '../../const';
import { BlockSubHeaderAffiliateInfo } from '../../components/BlockSubHeaderAffiliateInfo';
import { useGeneratePathWithStatisticsQueryParams } from '../../hooks/useGeneratePathWithStatisticsQueryParams';
import { StatisticBlockHeaderContent } from '../../components/StatisticBlockHeaderContent';
import { useGetCpaCompanyPartnersReport } from './hooks/useGetCpaCompanyPartnersReport';
import { CpaCompanyPartnersReportTable } from './components/CpaCompanyPartnersReportTable';
import { useGetExportCpaCompanyPartnerReport } from './hooks/useGetExportCpaCompanyPartnerReport';

interface Props {
  companyId: string;
}

const Component: FC<Props> = withBlockProvider(({ companyId }) => {
  const [isAllTimeCpa, setIsAllTimeCpa] = useState(false);
  const {
    data,
    loading,
    error,
    refetch,
    fetchMoreCallback,
    loadCpaCompanyPartnersReport,
    cpaCompanyPartnersReportList,
    hasNextPage,
    hasTotalRow,
    company,
  } = useGetCpaCompanyPartnersReport({ companyId, isAllTimeCpa });

  const exportReportConfig = useGetExportCpaCompanyPartnerReport({
    companyId,
    isAllTimeCpa,
  });

  useBlockComponentState({
    loadData: loadCpaCompanyPartnersReport,
    loading,
    error,
  });

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage,
    isFullScreenTable: true,
  });

  const { generatePathWithStatisticsQueryParams } =
    useGeneratePathWithStatisticsQueryParams();

  const customPathToPrevPage = generatePathWithStatisticsQueryParams({
    originalPath: Route.STATISTIC,
    queryParams: STATISTIC_QUERY_PARAMS,
  });

  const listExist = hasList(cpaCompanyPartnersReportList);

  return (
    <Block
      title={CPA_COMPANY_PARTNERS_STATISTIC_BLOCK_TITLE}
      id="cpaCompanyPartnersStatistic"
      headerContent={
        <StatisticBlockHeaderContent
          hasList={listExist}
          exportReportConfig={exportReportConfig}
          handleRefetchClick={refetch}
          rewardTariffType={RewardTariffType.Cpa}
          showAllCpa={(value) => setIsAllTimeCpa(value)}
        />
      }
      subHeader={
        company && (
          <BlockSubHeaderAffiliateInfo
            id={company.id}
            name={company.name}
            email={company.email}
            affiliateType={AffiliateTypeName.Company}
          />
        )
      }
      shrinkLoaderWrapper={false}
      emptyText={STATISTIC_NO_DATA}
      isEmpty={isListEmpty(cpaCompanyPartnersReportList)}
      shouldReturnToPrevPage
      isPermanentOpened
      customPathToPrevPage={customPathToPrevPage}
    >
      {listExist && (
        <CpaCompanyPartnersReportTable
          cpaCompanyPartnersReport={cpaCompanyPartnersReportList}
          hasTotalRow={hasTotalRow}
        />
      )}
    </Block>
  );
});

export const CpaCompanyPartnersReport = () => {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return <Component companyId={id} />;
};
