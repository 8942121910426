import { useCallback, useMemo } from 'react';
import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { useGetAccountsLazyQuery } from '../components/AccountsTable/queries/generated/GetAccounts';

const cellHeight = Heights.defaultCellHeight;
const COUNT_PER_PAGE = 10;

export const useGetAccounts = () => {
  const [loadAccountsList, { data, loading, error, fetchMore, refetch }] =
    useGetAccountsLazyQuery({
      variables: {
        first: getCountByWindowHeight(cellHeight, 0),
      },
    });

  const hasNextPage = data?.accounts?.pageInfo.hasNextPage;
  const endCursor = data?.accounts?.pageInfo.endCursor;

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore({
        variables: {
          first: COUNT_PER_PAGE,
          after: endCursor,
        },
      }),
    [endCursor, fetchMore],
  );

  const accountsList = useMemo(
    () => data?.accounts?.edges.map(({ node }) => node),
    [data?.accounts?.edges],
  );

  return {
    data,
    loading,
    error,
    refetch,
    hasNextPage,
    fetchMoreCallback,
    accountsList,
    loadAccountsList,
  };
};
