import { FC } from 'react';
import { withBlockProvider } from 'ui/Block/store';
import Block from 'ui/Block';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { MEDIA_ITEMS_BLOCK_TITLE, MEDIA_ITEMS_EMPTY_TEXT } from '../../const';
import { PromoCodeActivationsTable } from './components/PromoCodeActivationsTable';
import { useGetPromoCodeActivations } from './hooks/useGetPromoCodeActivations';
import { MediaItemsBlockHeaderContent } from '../MediaItemsBlockHeaderContent';
import { MediaItemsTabs } from '../MediaItemsTabs';
import { MediaItemTabs } from '../MediaItemsTabs/const';

export const MediaItemsPromoCodeActivations: FC = withBlockProvider(() => {
  const {
    promoCodeActivations,
    loading,
    loadPromoCodes,
    error,
    data,
    fetchMoreCallback,
    refetch,
    hasNextPage,
  } = useGetPromoCodeActivations();

  useBlockComponentState({
    loadData: loadPromoCodes,
    loading,
    error,
  });

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage,
    isFullScreenTable: true,
  });

  return (
    <Block
      title={MEDIA_ITEMS_BLOCK_TITLE}
      id="mediaItemsPromoCodeActivations"
      headerContent={
        <MediaItemsBlockHeaderContent handleRefetchClick={() => refetch()} />
      }
      subHeader={
        <MediaItemsTabs currentTabId={MediaItemTabs.PromoCodeActivation} />
      }
      shrinkLoaderWrapper={false}
      emptyText={MEDIA_ITEMS_EMPTY_TEXT}
      isEmpty={isListEmpty(promoCodeActivations)}
      shouldReturnToPrevPage={false}
      isPermanentOpened
    >
      {hasList(promoCodeActivations) && (
        <PromoCodeActivationsTable
          promoCodeActivations={promoCodeActivations}
        />
      )}
    </Block>
  );
});
