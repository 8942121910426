import { FC } from 'react';
import { DataCard } from 'commonComponents/DataCard';
import { Falsy, MediaItemRewardTariffTypeName } from 'commonTypes';
import { KeyValueRowProps } from 'ui/KeyValueRow';
import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import { CopyModalRow } from 'ui/CopyModalRow';
import Privilege from 'commonComponents/Privilege';
import { UserPrivilege } from 'generatedUserPrivilege';
import { getCountryISOtoDisplayName } from 'src/utils/getCountryISOtoDisplayName';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { ownerTypeNameMap, twoEmDash } from 'src/const';
import { useMediaItemReferralLinkModal } from './hooks/useMediaItemReferralLinkModal';
import { mapMediaItemType } from '../../../helpers';
import { MediaItemReferralLinkModalSteps } from './const';
import { EditMediaItemReferralLinkNameModal } from '../EditMediaItemReferralLinkNameModal';

interface Props {
  mediaItemReferralLinkId: string;
}

export const MediaItemReferralLinkModal: FC<Props> = ({
  mediaItemReferralLinkId,
}) => {
  const { mediaItemReferralLink, openEditNameModal, step } =
    useMediaItemReferralLinkModal({
      mediaItemReferralLinkId,
    });

  if (!mediaItemReferralLink) {
    return null;
  }

  const {
    owner,
    name,
    __typename,
    createdAt,
    code,
    mediaItemRewardTariff,
    mediaCampaign,
  } = mediaItemReferralLink;

  const isCpaTariff =
    mediaItemRewardTariff.__typename === MediaItemRewardTariffTypeName.Cpa;

  if (step === MediaItemReferralLinkModalSteps.EditNameStep) {
    return (
      <EditMediaItemReferralLinkNameModal
        mediaItemReferralLinkId={mediaItemReferralLinkId}
        name={name}
      />
    );
  }

  const rewardSum = isCpaTariff && mediaItemRewardTariff.rewardTariff.rewardSum;
  const baselineSum =
    isCpaTariff && mediaItemRewardTariff.rewardTariff.baselineSum;

  let email;

  if (owner?.__typename === 'Company') {
    email = owner.companyEmail;
  } else if (owner?.__typename === 'Partner') {
    email = owner.partnerEmail;
  } else {
    email = owner.company.email;
  }

  const rows: Array<KeyValueRowProps | Falsy> = [
    {
      title: 'Название',
      value: name,
    },
    {
      title: 'Тип аккаунта',
      value: ownerTypeNameMap[owner.__typename],
    },
    {
      title: 'Почта аккаунта',
      value: email || twoEmDash,
    },
    {
      title: 'Имя аккаунта',
      value: owner.name,
    },
    {
      title: 'Название медиакампании',
      value: mediaCampaign?.name,
    },
    {
      title: 'URL медиакампании',
      value: mediaCampaign?.url,
    },
    {
      title: 'Тип элемента',
      value: mapMediaItemType[__typename],
    },
    {
      title: 'Партнёрский тариф',
      value: mediaItemRewardTariff.rewardTariff.title,
    },
    isCpaTariff && {
      title: 'Резервный тариф',
      value: mediaItemRewardTariff.fallbackRewardTariff?.title,
    },
    isCpaTariff && {
      title: 'Геолокация',
      value: getCountryISOtoDisplayName(
        mediaItemRewardTariff.rewardTariff.countries,
      ),
    },
    baselineSum && {
      title: 'Baseline',
      value: formatSumWithCurrency(baselineSum.amount, baselineSum.currency),
    },
    rewardSum && {
      title: 'Вознаграждение',
      value: formatSumWithCurrency(rewardSum.amount, rewardSum.currency),
    },
    {
      title: 'Реферальный код',
      value: (
        <CopyModalRow value={code} toastText="Реферальный код скопирован" />
      ),
    },
    {
      title: 'Создана',
      value: formatDate(createdAt, DateFormat.DefaultDateOld),
    },
  ];

  return (
    <DataCard
      customFooter={
        <Privilege privileges={[UserPrivilege.UpdateReferralLinkMediaItemName]}>
          <Button
            size={ButtonSize.Large}
            onClick={openEditNameModal}
            theme={ButtonTheme.Process}
          >
            Редактировать
          </Button>
        </Privilege>
      }
      rows={rows}
    />
  );
};
