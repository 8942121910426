import { useContext } from 'react';
import { BlockContext } from 'ui/Block/store';
import Block from 'ui/Block';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import BlockTable from 'ui/Block/BlockTable';
import { ColumnDef } from '@tanstack/react-table';
import { SignInRestrictionHistoryData } from '../../queries/generated/SignInRestrictionHistoryData';
import { useGetSignInRestrictionHistory } from '../../hooks/useGetSignInRestrictionHistory';
import { useSignInRestrictionModal } from '../../hooks/useSignInRestrictionModal';

interface Props<T> {
  playerId: string;
  columns: Array<ColumnDef<T>>;
  shouldReturnToPrevPage: boolean;
  isFullScreenTable: boolean;
  routeToCustomPage?: string;
}

export const SignInRestrictionHistoryTable = <
  T extends { __typename: string },
>({
  playerId,
  columns,
  shouldReturnToPrevPage,
  isFullScreenTable,
  routeToCustomPage,
}: Props<T>) => {
  const { state } = useContext(BlockContext);
  const openSignInRestrictionModal = useSignInRestrictionModal();

  const {
    signInRestrictionHistoryData,
    signInRestrictionsList,
    error,
    loading,
    refetch,
    fetchMoreCallback,
  } = useGetSignInRestrictionHistory({ playerId, isFullScreenTable });

  useBlockComponentState({
    loading,
    error,
  });

  useBlockTableComponentState({
    data: signInRestrictionHistoryData,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage: signInRestrictionHistoryData?.pageInfo.hasNextPage,
    isFullScreenTable,
  });

  const handleRowClick = (
    signInRestriction: SignInRestrictionHistoryData,
    initiatorId: string,
  ) => {
    openSignInRestrictionModal({
      signInRestriction,
      initiatorId,
    });
  };

  return (
    <Block
      title="История блокировок"
      id="signInRestrictionHistory"
      headerContent={
        <ActionButton
          actionType={ButtonType.Update}
          hidden={!state.isOpened}
          disabled={state.isLoading}
          onClick={() => refetch()}
        />
      }
      shrinkLoaderWrapper={false}
      emptyText="Игроку не устанавливались блокировки"
      isEmpty={isListEmpty(signInRestrictionsList)}
      shouldReturnToPrevPage={shouldReturnToPrevPage}
    >
      {hasList(signInRestrictionsList) && (
        <BlockTable
          onClickOnRow={handleRowClick}
          // TODO: tech ticket https://eyeconweb.atlassian.net/browse/APN-1651
          columns={columns as Array<SignInRestrictionHistoryData>}
          data={signInRestrictionsList}
          routeToCustomPage={routeToCustomPage}
          isFullScreenTable={isFullScreenTable}
        />
      )}
    </Block>
  );
};
