import { useCallback, useMemo } from 'react';
import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { useGetMediaItemPromoCodeActivationsLazyQuery } from '../queries/generated/GetMediaItemPromoCodeActivations';

const COUNT_PER_PAGE = 10;
const cellHeight = Heights.defaultCellHeight;

export const useGetPromoCodeActivations = () => {
  const [
    loadPromoCodeActivations,
    { data, loading, error, fetchMore, refetch },
  ] = useGetMediaItemPromoCodeActivationsLazyQuery();

  const loadPromoCodeActivationsCallback = useCallback(
    () =>
      loadPromoCodeActivations({
        variables: {
          first: getCountByWindowHeight(cellHeight, 0),
        },
      }),
    [loadPromoCodeActivations],
  );

  const promoCodeActivations = useMemo(
    () => data?.promoCodeMediaItemActivations?.edges.map(({ node }) => node),
    [data?.promoCodeMediaItemActivations?.edges],
  );

  const endCursor = data?.promoCodeMediaItemActivations?.pageInfo.endCursor;

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: COUNT_PER_PAGE,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [endCursor, fetchMore],
  );

  return {
    promoCodeActivations,
    loadPromoCodes: loadPromoCodeActivationsCallback,
    loading,
    error,
    data,
    fetchMoreCallback,
    refetch,
    hasNextPage: data?.promoCodeMediaItemActivations?.pageInfo.hasNextPage,
  };
};
