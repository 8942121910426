import { FC } from 'react';
import { Tabs, TabFieldInterface } from 'ui/Tabs';
import { RewardTariffType } from 'commonTypes';
import { rewardTariffMap } from 'src/const';

interface Props {
  currentTabId?: RewardTariffType;
  onClick: (type: RewardTariffType) => void;
}

export const RewardTariffsTabs: FC<Props> = ({ currentTabId, onClick }) => {
  const tabs: Array<TabFieldInterface<RewardTariffType>> = [
    {
      id: RewardTariffType.RevShare,
      name: <span>{rewardTariffMap[RewardTariffType.RevShare]}</span>,
    },
    {
      id: RewardTariffType.Cpa,
      name: <span>{rewardTariffMap[RewardTariffType.Cpa]}</span>,
    },
  ];

  const handleTabChange = (id: RewardTariffType) => {
    onClick(id);
  };

  return (
    <Tabs
      tabs={tabs}
      currentTabId={currentTabId}
      onChangeTab={handleTabChange}
    />
  );
};
