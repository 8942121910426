import { FC } from 'react';
import Block from 'ui/Block';
import { withBlockProvider } from 'ui/Block/store';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { SearchContextProvider } from 'src/contexts/SearchContext';
import { PageTypeStorage } from 'src/utils/storage';
import SearchInput from 'ui/SearchInput';
import { useMediaItemsReferralLink } from './hooks/useMediaItemsReferralLink';
import { MediaItemsReferralLinkTable } from './components/MediaItemsReferralLinkTable';
import { MEDIA_ITEMS_BLOCK_TITLE, MEDIA_ITEMS_EMPTY_TEXT } from '../../const';
import { MediaItemsBlockHeaderContent } from '../MediaItemsBlockHeaderContent';
import { PostbackDisabledNotificationsBlock } from '../PostbackDisabledNotificationsBlock';
import styles from './MediaItemsReferralLink.module.scss';
import {
  REFERRAL_LINK_INPUT_NAME,
  REFERRAL_LINK_SEARCH_PLACEHOLDER,
} from './const';
import { useHasPostbackDisabledNotifications } from '../../hooks/useHasPostbackDisabledNotifications';
import { MediaItemTabs } from '../MediaItemsTabs/const';
import { MediaItemsTabs } from '../MediaItemsTabs';

const Component: FC = withBlockProvider(() => {
  const { refetchMediaItems, mediaItems, loadMediaItems } =
    useMediaItemsReferralLink();

  const postbackDisabledNotificationsConfig =
    useHasPostbackDisabledNotifications();

  return (
    <>
      <SearchInput
        name={REFERRAL_LINK_INPUT_NAME}
        placeholder={REFERRAL_LINK_SEARCH_PLACEHOLDER}
        onSubmit={loadMediaItems}
      />
      <Block
        title={MEDIA_ITEMS_BLOCK_TITLE}
        id="mediaItemsReferralLink"
        headerContent={
          <MediaItemsBlockHeaderContent
            handleRefetchClick={() => refetchMediaItems()}
          />
        }
        subHeader={<MediaItemsTabs currentTabId={MediaItemTabs.ReferralLink} />}
        extendedHeaderClassname={styles.extendedHeader}
        extendedHeader={
          !!mediaItems && (
            <PostbackDisabledNotificationsBlock
              config={postbackDisabledNotificationsConfig}
            />
          )
        }
        shrinkLoaderWrapper={false}
        emptyText={MEDIA_ITEMS_EMPTY_TEXT}
        isEmpty={isListEmpty(mediaItems)}
        shouldReturnToPrevPage={false}
        isPermanentOpened
      >
        {hasList(mediaItems) && (
          <MediaItemsReferralLinkTable mediaItemsReferralLink={mediaItems} />
        )}
      </Block>
    </>
  );
});

export const MediaItemsReferralLink = () => (
  <SearchContextProvider pageType={PageTypeStorage.MediaItemsReferralLink}>
    <Component />
  </SearchContextProvider>
);
