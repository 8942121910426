import { createColumnHelper } from '@tanstack/react-table';
import NowrapCell from 'ui/Table/components/NowrapCell';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { ColumnsSource } from 'ui/Table/types';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { currencySignMap } from 'src/const';
import { formatSumAmount } from 'utils/accounting';
import { MultilineCellWithLink } from 'ui/Table/components/MultilineCellWithLink';
import { generatePath } from 'react-router';
import { Route } from 'src/router/routes.const';
import { columnsIds, mapColumnIdToName, ColumnId } from '../const';
import { AccountData } from '../queries/generated/AccountData';

const columnHelper = createColumnHelper<AccountData>();

const columnsSource: ColumnsSource<AccountData> = [
  columnHelper.accessor(ColumnId.ExternalId, {
    id: ColumnId.ExternalId,
    header: mapColumnIdToName[ColumnId.ExternalId],
  }),
  columnHelper.accessor(ColumnId.Currency, {
    id: ColumnId.Currency,
    header: mapColumnIdToName[ColumnId.Currency],
    cell: ({ row }) => currencySignMap[row.original.balance.currency],
  }),
  columnHelper.accessor(ColumnId.Player, {
    id: ColumnId.Player,
    header: mapColumnIdToName[ColumnId.Player],
    cell: ({ row }) => {
      const { email, id, name } = row.original.player;
      const pathToPlayer = generatePath(Route.PLAYER, { id });

      return (
        <MultilineCellWithLink
          title={name}
          subTitle={email}
          route={pathToPlayer}
        />
      );
    },
  }),
  columnHelper.accessor(ColumnId.Balance, {
    id: ColumnId.Balance,
    header: mapColumnIdToName[ColumnId.Balance],
    cell: ({ row }) => formatSumAmount(row.original.balance.amount),
  }),
  columnHelper.accessor(ColumnId.BlockedSum, {
    id: ColumnId.BlockedSum,
    header: mapColumnIdToName[ColumnId.BlockedSum],
    cell: ({ row }) =>
      formatSumAmount(row.original.blockedWithdrawalSum.amount),
  }),
  columnHelper.accessor(ColumnId.CreatedAt, {
    id: ColumnId.CreatedAt,
    header: mapColumnIdToName[ColumnId.CreatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
];

export const useGetAccountsColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
