import { nbsp } from 'src/const';

export const EMPTY_PROMO_CODE_LIST = `Промокодов пока нет. Чтобы создать промокод, нажмите на${nbsp}плюс справа`;
export const EMPTY_REFERRAL_LINK_LIST = `Рефссылок пока нет. Чтобы создать рефссылку, нажмите на${nbsp}плюс справа`;

export enum ColumnId {
  Id = 'id',
  Title = 'name',
  ReferralLink = 'referralLink',
  RewardTariff = 'mediaItemRewardTariff.rewardTariff.title',
  MediaCampaign = 'mediaCampaign',
  PostbackCount = 'postbackCount',
  CreatedAt = 'createdAt',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.Id]: 'ID',
  [ColumnId.Title]: 'Название',
  [ColumnId.MediaCampaign]: 'Медиакампания',
  [ColumnId.ReferralLink]: 'Рефссылка',
  [ColumnId.RewardTariff]: 'Партнёрский тариф',
  [ColumnId.PostbackCount]: 'Постбэк',
  [ColumnId.CreatedAt]: 'Создана',
};

export const blockColumnsIds = [
  ColumnId.Title,
  ColumnId.MediaCampaign,
  ColumnId.ReferralLink,
  ColumnId.RewardTariff,
  ColumnId.CreatedAt,
];

export const pageColumnsIds = [
  ColumnId.Id,
  ColumnId.Title,
  ColumnId.MediaCampaign,
  ColumnId.ReferralLink,
  ColumnId.PostbackCount,
  ColumnId.RewardTariff,
  ColumnId.CreatedAt,
];
