import { FC, useContext } from 'react';
import Block from 'ui/Block';
import { BlockContext, withBlockProvider } from 'ui/Block/store';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useGetAccounts } from './hooks/useGetAccounts';
import { AccountsTable } from './components/AccountsTable';

export const Accounts: FC = withBlockProvider(() => {
  const { state } = useContext(BlockContext);

  const {
    data,
    loading,
    refetch,
    fetchMoreCallback,
    hasNextPage,
    accountsList,
    loadAccountsList,
    error,
  } = useGetAccounts();

  useBlockComponentState({
    loadData: loadAccountsList,
    loading,
    error,
  });

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage,
    isFullScreenTable: true,
  });

  return (
    <Block
      title="Счета"
      id="accounts"
      headerContent={
        <ActionButton
          actionType={ButtonType.Update}
          disabled={state.isLoading}
          onClick={() => refetch()}
        />
      }
      shrinkLoaderWrapper={false}
      emptyText="Счетов нет"
      isEmpty={isListEmpty(accountsList)}
      shouldReturnToPrevPage={false}
      isPermanentOpened
    >
      {hasList(accountsList) && <AccountsTable accountsList={accountsList} />}
    </Block>
  );
});
