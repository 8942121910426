import { FormikErrors } from 'formik';
import { checkIsValidUrl } from 'utils/validators';
import { CreateMediaCampaignValues } from '../../types';

export const validate = (
  values: CreateMediaCampaignValues,
): FormikErrors<CreateMediaCampaignValues> => {
  const errors: FormikErrors<CreateMediaCampaignValues> = {};

  if (!values.name) {
    errors.name = 'Введите название медиакампании';
  }

  if (values.name && values.name.length > 50) {
    errors.name = 'Название медиакампании не может быть длиннее 50 символов';
  }

  if (!values.url) {
    errors.url = 'Введите URL медиакампании';
  }

  if (values.url && !checkIsValidUrl(values.url)) {
    errors.url = 'Неверный формат';
  }

  if (values.url && values.url.length > 50) {
    errors.url = 'URL медиакампании не может быть длиннее 50 символов';
  }

  return errors;
};
