import { useCallback, useEffect, useMemo } from 'react';
import {
  Heights,
  getCountByWindowHeight,
} from 'src/utils/getCountByWindowHeight';
import { useGetSignInRestrictionHistoryLazyQuery } from '../queries/generated/GetSignInRestrictionHistory';
import { useGetSignInRestrictionHistoryBlockLazyQuery } from '../queries/generated/GetSignInRestrictionHistoryBlock';

const COUNT_PER_PAGE = 10;

interface UseEventGroupsParams {
  isFullScreenTable: boolean;
  playerId: string;
}
export const useGetSignInRestrictionHistory = ({
  playerId,
  isFullScreenTable,
}: UseEventGroupsParams) => {
  const currentUseGetSignInRestrictionHistory = isFullScreenTable
    ? useGetSignInRestrictionHistoryLazyQuery
    : useGetSignInRestrictionHistoryBlockLazyQuery;

  const [
    loadSignInRestrictionHistory,
    { data, loading, error, fetchMore, refetch },
  ] = currentUseGetSignInRestrictionHistory();

  const countPerPage = isFullScreenTable
    ? getCountByWindowHeight(Heights.defaultCellHeight)
    : COUNT_PER_PAGE;

  const signInRestrictionHistoryData = data?.player.signInRestrictions;

  const signInRestrictionsList = useMemo(
    () => signInRestrictionHistoryData?.edges.map(({ node }) => node),
    [signInRestrictionHistoryData],
  );

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: countPerPage,
            after: signInRestrictionHistoryData?.pageInfo.endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [countPerPage, signInRestrictionHistoryData?.pageInfo.endCursor, fetchMore],
  );

  useEffect(() => {
    loadSignInRestrictionHistory({
      variables: {
        playerId,
        first: countPerPage,
      },
    });
  }, [countPerPage, playerId, loadSignInRestrictionHistory]);

  return {
    signInRestrictionHistoryData,
    signInRestrictionsList,
    loading,
    error,
    refetch,
    fetchMoreCallback,
  };
};
