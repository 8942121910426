import { nbsp } from 'src/const';

export enum PartnerApplicationModalSteps {
  BaseStep = 1,
  DeclineStep,
  CreateReferralLink,
  UpdateReferralLink,
}

export const PARTNER_APPLICATIONS_SEARCH_PLACEHOLDER = `Найти заявку по${nbsp}её ID или по${nbsp}почте партнёра`;

export const PARTNER_APPLICATIONS_SEARCH_INPUT_NAME =
  'partnerApplicationsSearch';
