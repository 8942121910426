import { useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { toast } from 'react-toastify';
import { ApolloError } from '@apollo/client';
import errorToast from 'ui/Toast/ErrorToast';
import { ListAllOperations } from 'generatedGraphql';
import { RewardTariffType } from 'commonTypes';
import { useCreateCompanyReferralLinkMediaItem } from 'src/queries/generated/CreateCompanyReferralLinkMediaItem';
import { CreateCompanyReferralLinkValues } from '../validation';
import { FieldName } from '../const';

interface UseCreateCompanyReferralLinkResult {
  onCreateReferralLinkSubmitHandler: (
    companyId: string,
    values: CreateCompanyReferralLinkValues,
  ) => void;
  createCompanyReferralLinkLoading: boolean;
}

export const useCreateCompanyReferralLink = (
  isFullScreenTable: boolean,
): UseCreateCompanyReferralLinkResult => {
  const { dispatch } = useContext(ModalContext);

  const onCompleted = () => {
    toast.success('Вы создали новую рефссылку');

    dispatch({
      type: ModalActions.Close,
    });
  };

  const onError = (error: ApolloError) => {
    errorToast({
      header: error.name,
      text: error.message,
      toastId: 'create-company-referral-link-error',
    });
  };

  const [
    createCompanyReferralLink,
    { loading: createCompanyReferralLinkLoading },
  ] = useCreateCompanyReferralLinkMediaItem({
    onCompleted,
    onError,
    refetchQueries: [
      isFullScreenTable
        ? ListAllOperations.Query.GetCompanyReferralLinks
        : ListAllOperations.Query.GetCompanyReferralLinksBlock,
    ],
  });

  const onCreateReferralLinkSubmitHandler = (
    companyId: string,
    values: CreateCompanyReferralLinkValues,
  ) => {
    createCompanyReferralLink({
      variables: {
        input: {
          companyId,
          referralLinkMediaItem: {
            name: values[FieldName.MediaItemName],
            code: values[FieldName.Code],
            mediaCampaignId: values[FieldName.MediaCampaignId],
            mediaItemRewardTariff: {
              ...(values[FieldName.PaymentModel] === RewardTariffType.Cpa
                ? {
                    cpaRewardTariff: {
                      id: values[FieldName.CPATariffId],
                      fallbackRewardTariffId:
                        values[FieldName.FallbackTariffId],
                    },
                  }
                : {
                    revShareRewardTariff: {
                      id: values[FieldName.RevShareTariffId],
                    },
                  }),
            },
          },
        },
      },
    });
  };

  return {
    onCreateReferralLinkSubmitHandler,
    createCompanyReferralLinkLoading,
  };
};
