import { TabFieldInterface } from 'ui/Tabs';

export enum MediaItemTabs {
  ReferralLink = 'ReferralLink',
  PromoCode = 'PromoCode',
  PromoCodeActivation = 'PromoCodeActivation',
}

export const tabs: Array<TabFieldInterface<MediaItemTabs>> = [
  {
    id: MediaItemTabs.ReferralLink,
    name: <span>Ссылки</span>,
  },
  {
    id: MediaItemTabs.PromoCode,
    name: <span>Промокоды</span>,
  },
  {
    id: MediaItemTabs.PromoCodeActivation,
    name: <span>Активации промокодов</span>,
  },
];
