import { FC } from 'react';
import BlockTable from 'ui/Block/BlockTable';
import { useGetAccountsColumns } from './hooks/useGetAccountsColumns';
import { AccountData } from './queries/generated/AccountData';

interface Props {
  accountsList?: Array<AccountData>;
}

export const AccountsTable: FC<Props> = ({ accountsList }) => {
  const { columns } = useGetAccountsColumns();

  return <BlockTable columns={columns} data={accountsList} isFullScreenTable />;
};
