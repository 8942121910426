import { FC } from 'react';
import { withBlockProvider } from 'ui/Block/store';
import Block from 'ui/Block';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import SearchInput from 'ui/SearchInput';
import { PageTypeStorage } from 'src/utils/storage';
import { SearchContextProvider } from 'src/contexts/SearchContext';
import { MEDIA_ITEMS_BLOCK_TITLE, MEDIA_ITEMS_EMPTY_TEXT } from '../../const';
import { MediaItemsPromoCodeTable } from './components/MediaItemsPromoCodeTable';
import { useGetMediaItemsPromoCode } from './hooks/useGetMediaItemsPromoCode';
import { MediaItemsBlockHeaderContent } from '../MediaItemsBlockHeaderContent';
import { PostbackDisabledNotificationsBlock } from '../PostbackDisabledNotificationsBlock';
import styles from './MediaItemsPromoCode.module.scss';
import { useHasPostbackDisabledNotifications } from '../../hooks/useHasPostbackDisabledNotifications';
import { PROMO_CODE_INPUT_NAME, PROMO_CODE_SEARCH_PLACEHOLDER } from './const';
import { MediaItemTabs } from '../MediaItemsTabs/const';
import { MediaItemsTabs } from '../MediaItemsTabs';

const Component: FC = withBlockProvider(() => {
  const { mediaItemsPromoCode, refetchMediaItemsPromoCode, loadPromoCodes } =
    useGetMediaItemsPromoCode();

  const postbackDisabledNotificationsConfig =
    useHasPostbackDisabledNotifications();

  return (
    <>
      <SearchInput
        name={PROMO_CODE_INPUT_NAME}
        placeholder={PROMO_CODE_SEARCH_PLACEHOLDER}
        onSubmit={loadPromoCodes}
      />
      <Block
        title={MEDIA_ITEMS_BLOCK_TITLE}
        id="mediaItemsPromoCode"
        headerContent={
          <MediaItemsBlockHeaderContent
            handleRefetchClick={() => refetchMediaItemsPromoCode()}
          />
        }
        subHeader={<MediaItemsTabs currentTabId={MediaItemTabs.PromoCode} />}
        extendedHeaderClassname={styles.extendedHeader}
        extendedHeader={
          !!mediaItemsPromoCode && (
            <PostbackDisabledNotificationsBlock
              config={postbackDisabledNotificationsConfig}
            />
          )
        }
        shrinkLoaderWrapper={false}
        emptyText={MEDIA_ITEMS_EMPTY_TEXT}
        isEmpty={isListEmpty(mediaItemsPromoCode)}
        shouldReturnToPrevPage={false}
        isPermanentOpened
      >
        {hasList(mediaItemsPromoCode) && (
          <MediaItemsPromoCodeTable mediaItemsPromoCode={mediaItemsPromoCode} />
        )}
      </Block>
    </>
  );
});

export const MediaItemsPromoCode = () => (
  <SearchContextProvider pageType={PageTypeStorage.MediaItemsPromoCode}>
    <Component />
  </SearchContextProvider>
);
