import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type AssignCompanyApplicationVariables = SchemaTypes.Exact<{
  input: SchemaTypes.AssignCompanyApplicationInput;
}>;


export type AssignCompanyApplication = { __typename: 'Mutation', assignCompanyApplication: { __typename: 'AssignCompanyApplicationPayload', companyApplication: { __typename: 'CompanyApplication', id: string, status: SchemaTypes.CompanyApplicationStatus, updatedAt: string, admin?: { __typename: 'Admin', firstName: string, id: string, lastName: string } | null } } };


export const AssignCompanyApplicationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AssignCompanyApplication"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AssignCompanyApplicationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"assignCompanyApplication"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"companyApplication"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"admin"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}}]}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}}]}}]}}]}}]} as unknown as DocumentNode;
export type AssignCompanyApplicationMutationFn = Apollo.MutationFunction<AssignCompanyApplication, AssignCompanyApplicationVariables>;

/**
 * __useAssignCompanyApplication__
 *
 * To run a mutation, you first call `useAssignCompanyApplication` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignCompanyApplication` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignCompanyApplication, { data, loading, error }] = useAssignCompanyApplication({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignCompanyApplication(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignCompanyApplication, AssignCompanyApplicationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AssignCompanyApplication, AssignCompanyApplicationVariables>(AssignCompanyApplicationDocument, options);
      }
export type AssignCompanyApplicationHookResult = ReturnType<typeof useAssignCompanyApplication>;
export type AssignCompanyApplicationMutationResult = Apollo.MutationResult<AssignCompanyApplication>;
export type AssignCompanyApplicationMutationOptions = Apollo.BaseMutationOptions<AssignCompanyApplication, AssignCompanyApplicationVariables>;