import { FC } from 'react';
import styles from './TextOverflowMiddle.module.scss';

interface Props {
  text: string;
}

export const TextOverflowMiddle: FC<Props> = ({ text }) => {
  const halfTextLength = Math.round(text.length / 2);
  const textStartPart = text.substring(0, halfTextLength);
  const textEndPart = text.substring(halfTextLength);

  return (
    <div className={styles.container} data-testid="textOverflowMiddle">
      <span className={styles.textStartPart}>{textStartPart}</span>
      <span className={styles.textEndPart}>{textEndPart}</span>
    </div>
  );
};
