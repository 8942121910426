import { useContext } from 'react';
import { toast } from 'react-toastify';
import { ModalActions, ModalContext } from 'ui/Modal';
import errorToast from 'ui/Toast/ErrorToast';
import { ApolloError } from '@apollo/client';
import { getCountByWindowHeight, Heights } from 'utils/getCountByWindowHeight';
import { CreateMediaCampaignValues } from '../../../types';
import { GetMediaCampaignsDocument } from '../../../queries/generated/GetMediaCampaigns';
import { useCreateMediaCampaign as useCreateMediaCampaignMutation } from '../queries/generated/CreateMediaCampaign';

const cellHeight = Heights.defaultCellHeight;

export const useCreateMediaCampaign = () => {
  const { dispatch } = useContext(ModalContext);

  const handleCompleted = () => {
    toast.success('Вы создали новую медиакампанию');

    dispatch({
      type: ModalActions.Close,
    });
  };

  const handleError = (error: ApolloError) => {
    errorToast({
      header: 'Медиакампания не создана',
      text: error.message,
      toastId: 'create-media-campaign-error',
    });

    dispatch({
      type: ModalActions.Close,
    });
  };

  const [createMediaCampaignMutation, { error, data, loading }] =
    useCreateMediaCampaignMutation({
      refetchQueries: [
        {
          query: GetMediaCampaignsDocument,
          variables: {
            first: getCountByWindowHeight(cellHeight, 0),
          },
        },
      ],
      onCompleted: handleCompleted,
      onError: handleError,
    });

  const createMediaCampaign = ({ name, url }: CreateMediaCampaignValues) => {
    createMediaCampaignMutation({
      variables: {
        input: {
          name,
          url,
        },
      },
    });
  };

  return {
    createMediaCampaign,
    error,
    data,
    loading,
  };
};
