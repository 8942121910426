import { useContext } from 'react';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import Block from 'ui/Block';
import { ActionButton, ButtonType } from 'src/ui/ActionButton';
import { BlockContext } from 'ui/Block/store';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import BlockTable from 'ui/Block/BlockTable';
import { ColumnDef } from '@tanstack/react-table';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useGetPlayerAccounts } from './hooks/useGetPlayerAccounts';

interface Props<T extends object> {
  playerId: string;
  columns: Array<ColumnDef<T>>;
  isFullScreenTable: boolean;
  shouldReturnToPrevPage?: boolean;
  routeToCustomPage?: string;
}

export const AccountsTable = <T extends { __typename: string }>({
  playerId,
  columns,
  shouldReturnToPrevPage,
  routeToCustomPage,
  isFullScreenTable,
}: Props<T>) => {
  const { state } = useContext(BlockContext);

  const {
    data,
    loadAccounts,
    accountsList,
    loading,
    error,
    refetch,
    fetchMoreCallback,
    hasNextPage,
  } = useGetPlayerAccounts<T>({ playerId, isFullScreenTable });

  useBlockComponentState({
    loadData: loadAccounts,
    loading,
    error,
  });

  useBlockTableComponentState({
    data,
    loading,
    hasNextPage,
    isFullScreenTable,
    fetchMoreCallback,
    refetch,
  });

  return (
    <Block
      title="Счета"
      id="playerAccounts"
      headerContent={
        <ActionButton
          actionType={ButtonType.Update}
          hidden={!state.isOpened}
          disabled={state.isLoading}
          onClick={() => refetch?.()}
        />
      }
      isEmpty={isListEmpty(accountsList)}
      emptyText="Нет счетов"
      shrinkLoaderWrapper={false}
      shouldReturnToPrevPage={shouldReturnToPrevPage}
    >
      {hasList(accountsList) && (
        <BlockTable
          isFullScreenTable={isFullScreenTable}
          data={accountsList}
          columns={columns}
          routeToCustomPage={routeToCustomPage}
        />
      )}
    </Block>
  );
};
