import { FC } from 'react';
import { UserPrivilege } from 'generatedUserPrivilege';
import Privilege from 'commonComponents/Privilege/Privilege';
import { ClientPageLayout } from 'commonComponents/ClientPageLayout/ClientPageLayout';
import { useParams } from 'react-router';
import { DepositsBlock } from './components/DepositsBlock';
import { ExternalMoneyTransfersBlock } from './components/ExternalMoneyTransfersBlock';
import { PlayerDataBlock } from './components/PlayerDataBlock';
import { BonusesBlock } from './components/BonusesBlock';
import { AnalyticsBlock } from './components/AnalyticsBlock';
import { FreespinsBlock } from './components/FreespinsBlock';
import { WithdrawalsBlock } from './components/WithdrawalsBlock';
import { RestrictionsBlock } from './components/RestrictionsBlock';
import { DocumentsBlock } from './components/DocumentsBlock';
import { MessagesBlock } from './components/MessagesBlock';
import { TransfersBlock } from './components/TransfersBlock';
import { PlayerPageContainer } from './components/PlayerPageContainer';
import { AccountsHistoryBlock } from './components/AccountsHistoryBlock';
import { SignInRestrictionHistoryBlock } from './components/SignInRestrictionHistoryBlock';
import { TournamentRebuysBlock } from './components/TournamentRebuysBlock';
import { AccountsBlock } from './components/AccountsBlock';

export interface Props {
  playerId: string;
}

const Component: FC<Props> = ({ playerId }) => (
  <ClientPageLayout>
    <div>
      <PlayerDataBlock id={playerId} />

      <Privilege privileges={[UserPrivilege.ViewPlayerDeposit]}>
        <DepositsBlock id={playerId} />
      </Privilege>

      <Privilege privileges={[UserPrivilege.ViewPlayerBonus]}>
        <BonusesBlock id={playerId} />
      </Privilege>

      <Privilege
        privileges={[
          UserPrivilege.ViewPlayerAccount,
          UserPrivilege.ViewAccountEventgroup,
        ]}
      >
        <AccountsHistoryBlock id={playerId} />
      </Privilege>

      <Privilege privileges={[UserPrivilege.ViewPlayerMoneytransfer]}>
        <TransfersBlock id={playerId} />
      </Privilege>

      <Privilege privileges={[UserPrivilege.ViewPlayerSignInRestriction]}>
        <SignInRestrictionHistoryBlock id={playerId} />
      </Privilege>

      <Privilege privileges={[UserPrivilege.ViewPlayerExternalMoneyTransfer]}>
        <ExternalMoneyTransfersBlock id={playerId} />
      </Privilege>

      <Privilege privileges={[UserPrivilege.ViewPlayerAccount]}>
        <AccountsBlock id={playerId} />
      </Privilege>
    </div>

    <div>
      <Privilege
        privileges={[
          UserPrivilege.ViewPlayerAccount,
          UserPrivilege.ViewPlayerStatistic,
        ]}
      >
        <AnalyticsBlock id={playerId} />
      </Privilege>

      <Privilege privileges={[UserPrivilege.ViewPlayerWithdrawal]}>
        <WithdrawalsBlock id={playerId} />
      </Privilege>

      <Privilege privileges={[UserPrivilege.ViewPlayerFreespin]}>
        <FreespinsBlock id={playerId} />
      </Privilege>

      <Privilege privileges={[UserPrivilege.ViewPlayerRestriction]}>
        <RestrictionsBlock id={playerId} />
      </Privilege>

      <Privilege privileges={[UserPrivilege.ViewPlayerDocument]}>
        <DocumentsBlock id={playerId} />
      </Privilege>

      <Privilege privileges={[UserPrivilege.ViewPlayerMessage]}>
        <MessagesBlock id={playerId} />
      </Privilege>

      <Privilege privileges={[UserPrivilege.ViewPlayerTournamentrebuy]}>
        <TournamentRebuysBlock id={playerId} />
      </Privilege>
    </div>
  </ClientPageLayout>
);

export const Player = () => {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <PlayerPageContainer playerId={id} shouldProvideBlock={false}>
      <Component playerId={id} />
    </PlayerPageContainer>
  );
};
