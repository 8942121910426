export enum ColumnId {
  Player = 'player',
  Token = 'promoCodeMediaItem.promoCode.token',
  MediaCampaign = 'promoCodeMediaItem.mediaCampaign',
  Owner = 'promoCodeMediaItem.referralLink.owner',
  PromoCodeId = 'promoCodeMediaItem.promoCode.id',
  CreatedAt = 'createdAt',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.Player]: 'Игрок',
  [ColumnId.Token]: 'Токен',
  [ColumnId.MediaCampaign]: 'Медиакампания',
  [ColumnId.Owner]: 'Партнёр/Компания',
  [ColumnId.PromoCodeId]: 'ID промокода',
  [ColumnId.CreatedAt]: 'Дата, время',
};

export const columnsIds = [
  ColumnId.Player,
  ColumnId.Token,
  ColumnId.MediaCampaign,
  ColumnId.Owner,
  ColumnId.PromoCodeId,
  ColumnId.CreatedAt,
];
