import { useCallback, useContext, useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import Block from 'ui/Block';
import BlockTable from 'ui/Block/BlockTable';
import { BlockContext } from 'ui/Block/store';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { useGetPartnerMediaCampaignsLazyQuery } from '../../queries/generated/GetPartnerMediaCampaigns';

const PARTNER_MEDIA_CAMPAIGNS_COUNT_PER_PAGE = 10;
const cellHeight = Heights.defaultCellHeight;

interface Props<T extends object> {
  partnerId: string;
  columns: Array<ColumnDef<T>>;
  isFullScreenTable?: boolean;
  shouldReturnToPrevPage?: boolean;
  routeToCustomPage?: string;
}

export const PartnerMediaCampaignsTable = <T extends { __typename: string }>({
  partnerId,
  columns,
  isFullScreenTable,
  shouldReturnToPrevPage,
  routeToCustomPage,
}: Props<T>) => {
  const { state } = useContext(BlockContext);

  const [
    loadPartnerMediaCampaignsList,
    { data, error, loading, fetchMore, refetch },
  ] = useGetPartnerMediaCampaignsLazyQuery({
    variables: {
      id: partnerId,
      first: isFullScreenTable
        ? getCountByWindowHeight(cellHeight)
        : PARTNER_MEDIA_CAMPAIGNS_COUNT_PER_PAGE,
    },
  });

  useBlockComponentState({
    loadData: loadPartnerMediaCampaignsList,
    loading,
    error,
  });

  const partnerMediaCampaigns = data?.partner.mediaCampaigns;
  const endCursor = partnerMediaCampaigns?.pageInfo.endCursor;

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: PARTNER_MEDIA_CAMPAIGNS_COUNT_PER_PAGE,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [endCursor, fetchMore],
  );

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage: partnerMediaCampaigns?.pageInfo.hasNextPage,
    isFullScreenTable,
  });

  const partnerMediaCampaignsList = useMemo(
    () =>
      partnerMediaCampaigns?.edges.map(
        ({ node }) => node,
      ) as unknown as Array<T>,
    [partnerMediaCampaigns?.edges],
  );

  return (
    <Block
      emptyText="Медиакампаний нет"
      title="Медиакампании"
      id="partnerMediaCampaignsList"
      headerContent={
        <ActionButton
          actionType={ButtonType.Update}
          hidden={!state.isOpened}
          disabled={state.isLoading}
          onClick={() => refetch()}
        />
      }
      isEmpty={isListEmpty(partnerMediaCampaignsList)}
      shouldReturnToPrevPage={shouldReturnToPrevPage}
    >
      {hasList(partnerMediaCampaignsList) && (
        <BlockTable
          columns={columns}
          data={partnerMediaCampaignsList}
          isFullScreenTable={isFullScreenTable}
          routeToCustomPage={routeToCustomPage}
        />
      )}
    </Block>
  );
};
