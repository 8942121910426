import { FC } from 'react';
import { generatePath } from 'react-router';

import { withBlockProvider } from 'ui/Block/store';
import { Route } from 'src/router/routes.const';
import {
  SignInRestrictionHistoryTable,
  useGetSignInRestrictionsHistoryColumns,
} from './components/SignInRestrictionHistoryTable';

interface Props {
  id: string;
  shouldReturnToPrevPage?: boolean;
  isFullScreenTable?: boolean;
}

export const SignInRestrictionHistoryBlock: FC<Props> = withBlockProvider(
  ({ id, shouldReturnToPrevPage = false, isFullScreenTable = false }) => {
    const { columns } =
      useGetSignInRestrictionsHistoryColumns(isFullScreenTable);
    const routeToCustomPage = generatePath(
      Route.PLAYER_SIGN_IN_RESTRICTION_HISTORY,
      { id },
    );

    return (
      <SignInRestrictionHistoryTable
        playerId={id}
        columns={columns}
        shouldReturnToPrevPage={shouldReturnToPrevPage}
        isFullScreenTable={isFullScreenTable}
        routeToCustomPage={routeToCustomPage}
      />
    );
  },
);
