import { useCallback, useEffect, useMemo, useState } from 'react';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { useSearchContext } from 'src/contexts/SearchContext';
import { useGetPartnerApplicationsLazyQuery } from '../queries/generated/GetPartnerApplications';

const cellHeight = Heights.defaultCellHeight;

export const usePartnerApplicationsData = () => {
  const { searchValue } = useSearchContext();

  const [variables, setVariables] = useState({
    first: getCountByWindowHeight(cellHeight, 0),
  });

  useEffect(() => {
    setVariables((oldVariables) => ({
      ...oldVariables,
      search: searchValue,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [
    loadPartnerApplications,
    { data, loading, error, fetchMore, refetch },
  ] = useGetPartnerApplicationsLazyQuery();

  const loadPartnerApplicationsCallback = useCallback(
    () => loadPartnerApplications({ variables }),
    [loadPartnerApplications, variables],
  );

  useBlockComponentState({
    loadData: loadPartnerApplicationsCallback,
    loading,
    error,
  });

  const partnerApplicationsList = useMemo(
    () => data?.partnerApplications?.edges.map(({ node }) => node),
    [data?.partnerApplications?.edges],
  );

  const endCursor = data?.partnerApplications?.pageInfo.endCursor;

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: 10,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [endCursor, fetchMore],
  );

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage: data?.partnerApplications?.pageInfo.hasNextPage,
    isFullScreenTable: true,
  });

  return {
    refetchPartnerApplicationsList: refetch,
    partnerApplicationsList,
    loadPartnerApplications: () =>
      loadPartnerApplications({
        variables: { ...variables, search: searchValue },
      }),
  };
};
