import { GetCompaniesForDropdown } from '../queries/generated/GetCompaniesForDropdown';

export const getCompaniesOptions = (
  companies?: GetCompaniesForDropdown['companies']['edges'],
) =>
  companies?.map(({ node }) => ({
    label: node.name,
    id: node.id,
    labelRight: node.email,
  }));
