import { FC } from 'react';
import { generatePath } from 'react-router';
import { RouterLink } from 'ui/RouterLink';
import { RouterLinkSize } from 'ui/RouterLink/const';
import { Route } from 'src/router/routes.const';
import styles from './BlockSubHeaderCompanyAffiliateInfo.module.scss';

interface Props {
  companyName: string;
  companyId: string;
  affiliateName: string;
}

export const BlockSubHeaderCompanyAffiliateInfo: FC<Props> = ({
  companyName,
  companyId,
  affiliateName,
}) => {
  const route = generatePath(Route.COMPANY, {
    id: companyId,
  });

  return (
    <div className={styles.subHeader}>
      <div className={styles.companyNameWrapper}>
        <span className={styles.label}>Компания</span>{' '}
        <RouterLink path={route} size={RouterLinkSize.Large}>
          {companyName}
        </RouterLink>
      </div>
      <div>
        <span className={styles.label}>Партнёр компании</span>{' '}
        <span className={styles.value}>{affiliateName}</span>
      </div>
    </div>
  );
};
