import { useFormikContext } from 'formik';
import errorToast from 'ui/Toast/ErrorToast';
import { nbsp } from 'src/const';
import { FieldName } from '../../../const';
import { CreatePromoCodeValues } from '../../../types';

interface useToggleBlockHandlerResult {
  handleToggleBlockSwitch: (isBlockOpened: boolean) => void;
}

export const useToggleBlockHandler = (): useToggleBlockHandlerResult => {
  const { setFieldValue, validateForm, setTouched } =
    useFormikContext<CreatePromoCodeValues>();

  const handleToggleBlockSwitch = (isBlockOpened: boolean) => {
    setFieldValue(FieldName.ConnectionWithReferralLink, isBlockOpened)
      .then(() => validateForm())
      .catch((error) => {
        errorToast({
          header: `Связь с${nbsp}реферальной ссылкой`,
          text: error,
          toastId: 'link-with-referral-link',
        });
      });

    if (!isBlockOpened) {
      setFieldValue(FieldName.ReferralLink, undefined);
      setFieldValue(FieldName.ReferralCodeId, undefined);

      // Reset media campaign after set by referral link query if user changed his mind
      setFieldValue(FieldName.MediaCampaignName, undefined);
      setFieldValue(FieldName.MediaCampaignUrl, undefined);
    }

    // Reset touched fields for clear validation process
    setTouched({
      [FieldName.MediaCampaignId]: false,
      [FieldName.Usage]: false,
    });

    // Reset fields because its depends on referral link block state
    setFieldValue(FieldName.MediaCampaignId, undefined);
    setFieldValue(FieldName.Usage, undefined);
  };

  return {
    handleToggleBlockSwitch,
  };
};
