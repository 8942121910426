import { useContext } from 'react';
import { ColumnDef } from '@tanstack/react-table';

import { BlockContext } from 'ui/Block/store';
import Block from 'ui/Block';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import BlockTable from 'ui/Block/BlockTable';

import { AccountEventGroupBasicData } from '../../queries/fragments/generated/AccountEventGroupBasicData';
import { AccountEventGroupData } from '../../queries/fragments/generated/AccountEventGroupData';
import { useAccounts } from '../../hooks/useAccounts';
import { useGetEventGroups } from '../../hooks/useGetEventGroups';
import { AccountsTabs } from '../AccountsTabs';
import { useAccountEventGroupModal } from '../../hooks/useAccountEventGroupModal';

interface Props<T> {
  playerId: string;
  columns: Array<ColumnDef<T>>;
  shouldReturnToPrevPage: boolean;
  isFullScreenTable: boolean;
  routeToCustomPage?: string;
}

export const AccountHistoryTable = <T extends { __typename: string }>({
  playerId,
  columns,
  shouldReturnToPrevPage,
  isFullScreenTable,
  routeToCustomPage,
}: Props<T>) => {
  const openAccountEventsModal = useAccountEventGroupModal();

  const { state } = useContext(BlockContext);

  const handleRowClick = (
    accountEventGroup: AccountEventGroupBasicData | AccountEventGroupData,
    initiatorId: string,
  ) => {
    openAccountEventsModal({
      accountEventGroup,
      initiatorId,
    });
  };

  const {
    currentAccountId,
    accounts,
    accountsError,
    accountsLoading,
    accountsLoad,
    setCurrentAccountId,
    accountsRefetch,
  } = useAccounts({ playerId });

  const {
    eventGroupsData,
    eventGroups,
    eventGroupsError,
    eventGroupsLoading,
    evensGroupsFetchMore,
    eventGroupsRefetch,
  } = useGetEventGroups({
    isFullScreenTable,
    currentAccountId,
  });

  useBlockComponentState({
    loadData: accountsLoad,
    loading: accountsLoading || eventGroupsLoading,
    error: accountsError || eventGroupsError,
  });

  useBlockTableComponentState({
    data: eventGroupsData,
    fetchMoreCallback: evensGroupsFetchMore,
    refetch: eventGroupsRefetch,
    loading: eventGroupsLoading,
    hasNextPage: eventGroupsData?.pageInfo.hasNextPage,
    isFullScreenTable,
  });

  const handleTabChange = (tabId: string) => {
    setCurrentAccountId(tabId);
  };

  return (
    <Block
      title="История счета"
      id="playerAccountsHistory"
      headerContent={
        <ActionButton
          actionType={ButtonType.Update}
          hidden={!state.isOpened}
          disabled={state.isLoading}
          onClick={() => accountsRefetch()}
        />
      }
      subHeader={
        !accountsLoading &&
        !!accounts?.length && (
          <AccountsTabs
            accounts={accounts}
            currentTabId={currentAccountId}
            setCurrentTabId={handleTabChange}
          />
        )
      }
      shrinkLoaderWrapper={false}
      emptyText="Игрок не совершал операций"
      isEmpty={isListEmpty(accounts) || isListEmpty(eventGroups)}
      shouldReturnToPrevPage={shouldReturnToPrevPage}
    >
      {hasList(eventGroups) && (
        <BlockTable
          onClickOnRow={handleRowClick}
          // TODO: tech ticket https://eyeconweb.atlassian.net/browse/APN-1651
          columns={
            columns as Array<AccountEventGroupBasicData | AccountEventGroupData>
          }
          data={eventGroups}
          routeToCustomPage={routeToCustomPage}
          isFullScreenTable={isFullScreenTable}
        />
      )}
    </Block>
  );
};
