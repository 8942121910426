import { FC } from 'react';
import { generatePath } from 'react-router';
import { withBlockProvider } from 'ui/Block/store';
import { Route } from 'src/router/routes.const';
import {
  AccountHistoryTable,
  useGetAccountEventGroupsColumns,
} from './components/AccountHistoryTable';

interface Props {
  id: string;
  shouldReturnToPrevPage?: boolean;
  isFullScreenTable?: boolean;
}

export const AccountsHistoryBlock: FC<Props> = withBlockProvider(
  ({ id, shouldReturnToPrevPage = false, isFullScreenTable = false }) => {
    const { columns } = useGetAccountEventGroupsColumns(isFullScreenTable);
    const routeToCustomPage = generatePath(Route.PLAYER_ACCOUNTS_HISTORY, {
      id,
    });

    return (
      <AccountHistoryTable
        playerId={id}
        columns={columns}
        isFullScreenTable={isFullScreenTable}
        shouldReturnToPrevPage={shouldReturnToPrevPage}
        routeToCustomPage={routeToCustomPage}
      />
    );
  },
);
