import { nbsp } from 'src/const';

export enum ColumnId {
  ExternalId = 'externalId',
  Player = 'player',
  Currency = 'balance.currency',
  Balance = 'balance.amount',
  BlockedSum = 'blockedWithdrawalSum.amount',
  CreatedAt = 'createdAt',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.ExternalId]: 'Внешний ID',
  [ColumnId.Player]: 'Пользователь',
  [ColumnId.Currency]: 'Валюта',
  [ColumnId.Balance]: 'Баланс',
  [ColumnId.BlockedSum]: `Сумма на${nbsp}вывод`,
  [ColumnId.CreatedAt]: 'Создан',
};

export const columnsIds = [
  ColumnId.ExternalId,
  ColumnId.Player,
  ColumnId.Currency,
  ColumnId.Balance,
  ColumnId.BlockedSum,
  ColumnId.CreatedAt,
];
