import { FC } from 'react';
import { OwnerTypeName } from 'commonTypes';
import { RouterLink } from 'ui/RouterLink';
import { RouterLinkSize } from 'ui/RouterLink/const';
import { generatePath } from 'react-router';
import { Route } from 'src/router/routes.const';
import styles from './ReferralLinkReportBlockSubHeader.module.scss';
import { GetCpaReferralLinkReport } from '../../pages/CpaReferralLinkReport/queries/generated/GetCpaReferralLinkReport';
import { GetRevShareReferralLinkReport } from '../../pages/RevShareReferralLinkReport/queries/generated/GetRevShareReferralLinkReport';

type ReferralLinkOwner =
  | GetRevShareReferralLinkReport['mediaItem']['owner']
  | GetCpaReferralLinkReport['mediaItem']['owner'];

export interface ReferralLinkReportBlockSubHeaderProps {
  mediaItemInfo?: {
    name: string;
    code: string;
    owner: ReferralLinkOwner;
  };
}

export const ReferralLinkReportBlockSubHeader: FC<
  ReferralLinkReportBlockSubHeaderProps
> = ({ mediaItemInfo }) => {
  if (!mediaItemInfo) {
    return null;
  }

  const { owner } = mediaItemInfo;
  const isPartner = owner.__typename === OwnerTypeName.Partner;
  const affiliateName =
    owner.__typename === OwnerTypeName.CompanyAffiliate
      ? owner.company.name
      : owner.name;

  const route = generatePath(
    OwnerTypeName.CompanyAffiliate === owner.__typename ||
      OwnerTypeName.Company === owner.__typename
      ? Route.COMPANY
      : Route.PARTNER,
    {
      id:
        owner.__typename === OwnerTypeName.CompanyAffiliate
          ? owner.company.id
          : owner.id,
    },
  );

  return (
    <div
      className={styles.subHeader}
      data-testid="referralLinkReportBlockSubHeader"
    >
      <div data-testid="affiliateName" className={styles.affiliateNameWrapper}>
        <span className={styles.title}>{`${
          isPartner ? 'Партнёр' : 'Компания'
        }`}</span>
        <RouterLink path={route} size={RouterLinkSize.Large}>
          {affiliateName}
        </RouterLink>
      </div>
      {!isPartner && (
        <div data-testid="ownerName">
          <span className={styles.title}>Владелец медиа</span>
          <span>{owner.name}</span>
        </div>
      )}
      <div data-testid="mediaItemName">
        <span className={styles.title}>Реф. код</span>
        <span>{`${mediaItemInfo.name} (${mediaItemInfo.code})`}</span>
      </div>
    </div>
  );
};
