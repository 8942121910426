import { FC } from 'react';
import BlockTable from 'ui/Block/BlockTable';
import { useGetPromoCodeActivationsColumns } from './hooks/useGetPromoCodeActivationsColumns';
import { MediaItemPromoCodeActivationData } from '../../queries/generated/MediaItemPromoCodeActivationData';
import { useOpenMediaItemPromoCodeModal } from '../../../../hooks/useOpenMediaItemPromoCodeModal';

interface Props {
  promoCodeActivations?: Array<MediaItemPromoCodeActivationData>;
}

export const PromoCodeActivationsTable: FC<Props> = ({
  promoCodeActivations,
}) => {
  const { columns } = useGetPromoCodeActivationsColumns();
  const openModal = useOpenMediaItemPromoCodeModal();

  return (
    <BlockTable
      onClickOnRow={({ promoCodeMediaItem }, initiatorId) =>
        openModal({
          promoCodeId: promoCodeMediaItem.id,
          initiatorId,
        })
      }
      columns={columns}
      data={promoCodeActivations}
      isFullScreenTable
    />
  );
};
