import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { MediaCampaignModal } from '../../MediaCampaignModal';
import { MediaCampaignData } from '../../../queries/generated/MediaCampaignData';

interface OpenModalParams {
  mediaCampaign: MediaCampaignData;
  initiatorId: string;
}

type UseMediaItemsModalResult = (params: OpenModalParams) => void;

export const useMediaItemModal = (): UseMediaItemsModalResult => {
  const { dispatch: modalDispatch } = useContext(ModalContext);

  const openModal = useCallback(
    async ({ mediaCampaign: { id }, initiatorId }: OpenModalParams) => {
      modalDispatch({
        type: ModalActions.Open,
        payload: {
          title: `#${id}`,
          subTitle: 'Медиаэлемент',
          content: <MediaCampaignModal mediaCampaignId={id} />,
          initiatorId,
        },
      });
    },
    [modalDispatch],
  );

  return openModal;
};
