import { FC } from 'react';
import { withBlockProvider } from 'ui/Block/store';
import { generatePath } from 'react-router';
import { Route } from 'src/router/routes.const';
import { useGetPlayerAccountsColumns } from './components/AccountsTable/hooks/useGetPlayerAccountsColumns';
import { AccountsTable } from './components/AccountsTable';

interface Props {
  id: string;
}

export const AccountsBlock: FC<Props> = withBlockProvider(
  ({ id: playerId }) => {
    const { columns } = useGetPlayerAccountsColumns(false);

    return (
      <AccountsTable
        playerId={playerId}
        columns={columns}
        routeToCustomPage={generatePath(Route.PLAYER_ACCOUNTS, {
          id: playerId,
        })}
        isFullScreenTable={false}
      />
    );
  },
);
