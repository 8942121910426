import { FC, useContext, useState } from 'react';
import { MediaItemTypeName } from 'commonTypes';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import { MediaItemsTabs } from 'commonComponents/MediaItemsTabs';
import { isListEmpty } from 'ui/Block/BlockTable/utils';
import Block from 'ui/Block';
import { BlockContext, withBlockProvider } from 'ui/Block/store';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import Privilege from 'commonComponents/Privilege';
import { UserPrivilege } from 'generatedUserPrivilege';
import { useGetMediaItemsData } from './hooks/useGetMediaItemsData';
import styles from './CompanyMediaItemsBlock.module.scss';
import { MediaItemTable } from './components/MediaItemTable';
import { useOpenCreateReferralLinkModal } from './components/ReferralLinkMediaItemTable/hooks/useOpenCreateReferralLinkModal';
import { EMPTY_PROMO_CODE_LIST, EMPTY_REFERRAL_LINK_LIST } from './const';

interface Props {
  id: string;
  isFullScreenTable: boolean;
  shouldReturnToPrevPage?: boolean;
}

export const CompanyMediaItemsBlock: FC<Props> = withBlockProvider(
  ({ id, isFullScreenTable, shouldReturnToPrevPage }) => {
    const { state } = useContext(BlockContext);

    const [mediaItemsType, setMediaItemsType] = useState<MediaItemTypeName>(
      MediaItemTypeName.ReferralLink,
    );

    const { openCreateReferralLinkModal } = useOpenCreateReferralLinkModal({
      companyId: id,
      isFullScreenTable,
    });

    const {
      mediaItems,
      refetch,
      loadCompanyMediaItem,
      loading,
      error,
      fetchMore,
      hasNextPage,
      mediaItemsData,
      columns,
    } = useGetMediaItemsData({
      mediaItemsType,
      companyId: id,
      isFullScreenTable,
    });

    useBlockComponentState({
      loadData: loadCompanyMediaItem,
      loading,
      error,
    });

    useBlockTableComponentState({
      data: mediaItemsData,
      fetchMoreCallback: fetchMore,
      refetch,
      loading,
      hasNextPage,
      isFullScreenTable,
    });

    const emptyBlockText =
      mediaItemsType === MediaItemTypeName.PromoCode
        ? EMPTY_PROMO_CODE_LIST
        : EMPTY_REFERRAL_LINK_LIST;

    return (
      <Block
        title="Медиаэлементы"
        id="companyMediaItems"
        headerContent={
          <div className={styles.headerContent}>
            <Privilege
              privileges={[UserPrivilege.CreateCompanyReferralLinkMediaItem]}
            >
              <ActionButton
                actionType={ButtonType.Add}
                onClick={openCreateReferralLinkModal}
              />
            </Privilege>
            <ActionButton
              actionType={ButtonType.Update}
              hidden={!state.isOpened}
              disabled={state.isLoading}
              onClick={() => refetch()}
            />
          </div>
        }
        subHeader={
          <MediaItemsTabs
            currentTabId={mediaItemsType}
            onClick={setMediaItemsType}
          />
        }
        shrinkLoaderWrapper={false}
        emptyText={emptyBlockText}
        isEmpty={isListEmpty(mediaItems)}
        shouldReturnToPrevPage={shouldReturnToPrevPage}
      >
        <MediaItemTable
          isFullScreenTable={isFullScreenTable}
          mediaItemsType={mediaItemsType}
          mediaItems={mediaItems}
          // TODO: tech ticket https://eyeconweb.atlassian.net/browse/APN-1651
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          columns={columns as any}
          companyId={id}
        />
      </Block>
    );
  },
);
