import { FC, useContext } from 'react';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import Block from 'ui/Block';
import { BlockContext, withBlockProvider } from 'ui/Block/store';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import SearchInput from 'ui/SearchInput';
import { PageTypeStorage } from 'utils/storage';
import { SearchContextProvider } from 'src/contexts/SearchContext';
import { usePartnerApplicationsData } from './hooks/usePartnerApplicationsData';
import { PartnerApplicationsTable } from './components/PartnerApplicationsTable';

import {
  PARTNER_APPLICATIONS_SEARCH_INPUT_NAME,
  PARTNER_APPLICATIONS_SEARCH_PLACEHOLDER,
} from './const';

const Component: FC = withBlockProvider(() => {
  const {
    refetchPartnerApplicationsList,
    partnerApplicationsList,
    loadPartnerApplications,
  } = usePartnerApplicationsData();
  const { state } = useContext(BlockContext);

  return (
    <>
      <SearchInput
        name={PARTNER_APPLICATIONS_SEARCH_INPUT_NAME}
        placeholder={PARTNER_APPLICATIONS_SEARCH_PLACEHOLDER}
        onSubmit={loadPartnerApplications}
      />
      <Block
        title="Заявки партнёров"
        id="partnerApplications"
        headerContent={
          <ActionButton
            actionType={ButtonType.Update}
            hidden={false}
            disabled={state.isLoading}
            onClick={() => refetchPartnerApplicationsList()}
          />
        }
        shrinkLoaderWrapper={false}
        emptyText="Заявок партнёров нет"
        isEmpty={isListEmpty(partnerApplicationsList)}
        shouldReturnToPrevPage={false}
        isPermanentOpened
      >
        {hasList(partnerApplicationsList) && (
          <PartnerApplicationsTable
            partnerApplications={partnerApplicationsList}
          />
        )}
      </Block>
    </>
  );
});

export const PartnerApplications = () => (
  <SearchContextProvider pageType={PageTypeStorage.PartnerApplicationsPage}>
    <Component />
  </SearchContextProvider>
);
