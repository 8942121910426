export enum ColumnId {
  Id = 'id',
  Title = 'name',
  Url = 'url',
  CreatedAt = 'createdAt',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.Id]: 'ID',
  [ColumnId.Title]: 'Название',
  [ColumnId.Url]: 'URL',
  [ColumnId.CreatedAt]: 'Создана',
};

export const columnsIds = [
  ColumnId.Id,
  ColumnId.Title,
  ColumnId.Url,
  ColumnId.CreatedAt,
];
