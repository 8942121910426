import { useNavigate } from 'react-router-dom';
import { logError } from 'utils/logError';
import { Route } from 'src/router/routes.const';
import { MediaItemTabs } from '../const';

export const useGetHandleChangeTab = () => {
  const navigate = useNavigate();

  const onChangeTab = (id: MediaItemTabs) => {
    switch (id) {
      case MediaItemTabs.ReferralLink:
        navigate(Route.REFERRAL_LINK_MEDIA_ITEMS);
        break;
      case MediaItemTabs.PromoCode:
        navigate(Route.PROMO_CODE_MEDIA_ITEMS);
        break;
      case MediaItemTabs.PromoCodeActivation:
        navigate(Route.PROMO_CODE_MEDIA_ITEM_ACTIVATIONS);
        break;
      default:
        logError(`MediaItemTab with id ${id} doesn't have route`);
    }
  };

  return onChangeTab;
};
