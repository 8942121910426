export enum ColumnId {
  Id = 'id',
  Token = 'promoCode.token',
  Activations = 'promoCode.activation',
  MediaCampaign = 'mediaCampaign',
  UsageType = 'usageType',
  ReferralCode = 'referralLink.code',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.Id]: 'ID',
  [ColumnId.Token]: 'Токен',
  [ColumnId.Activations]: 'Активации',
  [ColumnId.MediaCampaign]: 'Медиакампания',
  [ColumnId.UsageType]: 'Использование',
  [ColumnId.ReferralCode]: 'Реферальный код',
  [ColumnId.CreatedAt]: 'Создан',
  [ColumnId.UpdatedAt]: 'Обновлен',
};

export const columnsIds = [
  ColumnId.Id,
  ColumnId.Token,
  ColumnId.Activations,
  ColumnId.MediaCampaign,
  ColumnId.UsageType,
  ColumnId.ReferralCode,
  ColumnId.CreatedAt,
  ColumnId.UpdatedAt,
];
