import { useContext } from 'react';
import { ModalActions, ModalContext, useModalManageData } from 'ui/Modal';
import { UpdateReferralLinkMediaItemNameInput } from 'generatedGraphql';
import { toast } from 'react-toastify';
import { useUpdateReferralLinkMediaItemName } from '../queries/generated/UpdateReferralLinkMediaItemName';

interface Params {
  mediaItemReferralLinkId: string;
}

export const useEditMediaItemReferralLinkNameModal = ({
  mediaItemReferralLinkId,
}: Params) => {
  const [updateReferralLinkMediaItemName, { error, loading, data }] =
    useUpdateReferralLinkMediaItemName();

  const { dispatch } = useContext(ModalContext);

  const handleError = () => {
    dispatch({ type: ModalActions.Close });
    toast.error('Параметры рефссылки не изменены');
  };

  const handleComplete = () => {
    dispatch({ type: ModalActions.Close });
    toast.success('Вы изменили параметры рефссылки');
  };

  const onEditName = async (values: UpdateReferralLinkMediaItemNameInput) => {
    await updateReferralLinkMediaItemName({
      variables: {
        input: {
          id: mediaItemReferralLinkId,
          name: values.name,
        },
      },
      onError: handleError,
      onCompleted: handleComplete,
    });
  };

  useModalManageData({
    loading,
    errorConfig: { error, toastId: 'edit-media-item-name-error' },
    hasData: !!data,
  });

  return { onEditName, loading, error };
};
