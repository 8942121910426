import { useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { CreateMediaItemReferralLink } from '../../MediaItemsReferralLink/components/CreateMediaItemReferralLink';
import { CreateMediaElementOptionsIds } from '../const';
import { CreateMediaItemPromoCode } from '../../MediaItemsPromoCode/components/CreateMediaItemPromoCode';

export const useGetCreateMediaItemAction = () => {
  const { dispatch: modalDispatch } = useContext(ModalContext);

  const openCreateReferralLinkModal = () => {
    modalDispatch({
      type: ModalActions.Open,
      payload: {
        title: 'Создать рефссылку',
        content: <CreateMediaItemReferralLink />,
      },
    });
  };

  const openCreatePromoCodeModal = () => {
    modalDispatch({
      type: ModalActions.Open,
      payload: {
        title: 'Создание промокода',
        content: <CreateMediaItemPromoCode />,
        hasGoBackButtonForSteps: false,
      },
    });
  };

  const mapOptionToAction: {
    [key in CreateMediaElementOptionsIds]: () => void;
  } = {
    [CreateMediaElementOptionsIds.ReferralLink]: openCreateReferralLinkModal,
    [CreateMediaElementOptionsIds.PromoCode]: openCreatePromoCodeModal,
  };

  return { mapOptionToAction };
};
