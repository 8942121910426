import { GetPartnersForDropdown } from '../queries/generated/GetPartnersForDropdown';

export const getPartnersOptions = (
  partners?: GetPartnersForDropdown['partners']['edges'],
) =>
  partners?.map(({ node }) => ({
    label: node.name,
    id: node.id,
    labelRight: node?.email ?? '',
  }));
