import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { MediaItemReferralLinkData } from 'src/queries/generated/MediaItemReferralLinkData';
import { MediaItemReferralLinkModal } from '../../MediaItemReferralLinkModal';

interface OpenModalParams {
  mediaItemReferralLink: MediaItemReferralLinkData;
  initiatorId: string;
}

type UseMediaItemsReferralLinkModalResult = (params: OpenModalParams) => void;

export const useMediaItemReferralLinkModal =
  (): UseMediaItemsReferralLinkModalResult => {
    const { dispatch: modalDispatch } = useContext(ModalContext);

    const openModal = useCallback(
      async ({
        mediaItemReferralLink: { id },
        initiatorId,
      }: OpenModalParams) => {
        modalDispatch({
          type: ModalActions.Open,
          payload: {
            title: `#${id}`,
            subTitle: 'Медиаэлемент',
            content: (
              <MediaItemReferralLinkModal mediaItemReferralLinkId={id} />
            ),
            initiatorId,
          },
        });
      },
      [modalDispatch],
    );

    return openModal;
  };
