export enum ButtonType {
  Add,
  Archive,
  Cancel,
  Check,
  CheckDouble,
  Close,
  Delete,
  Edit,
  FileExport,
  Filter,
  GoBack,
  History,
  Settings,
  Share,
  Update,
}

export enum ButtonSize {
  Small = 'small',
  Big = 'big',
}

export enum HoverColor {
  BLUE = 'blue',
  RED = 'red',
}
