import { toast } from 'react-toastify';
import { useFormikContext } from 'formik';
import { FocusEvent } from 'react';
import { useGetReferralLinkMediaItemByCodeLazyQuery } from 'src/queries/generated/GetReferralLinkMediaItemByCode';
import { FieldName } from '../../../const';
import { CreatePromoCodeValues } from '../../../types';

interface useGetReferralLinkMediaItemByUrlResult {
  getReferralLinkByUrl: (event: FocusEvent<HTMLInputElement>) => void;
  getReferralLinkByUrlLoading: boolean;
}

export const useGetReferralLinkMediaItemByCode =
  (): useGetReferralLinkMediaItemByUrlResult => {
    const { setFieldValue, values, validateForm } =
      useFormikContext<CreatePromoCodeValues>();

    const [
      getReferralLinkMediaItemByUrlQuery,
      { loading: getReferralLinkByUrlLoading },
    ] = useGetReferralLinkMediaItemByCodeLazyQuery();

    const getReferralLinkByUrl = (event: FocusEvent<HTMLInputElement>) => {
      const referalCode = event.target.value;

      if (referalCode === '') {
        return;
      }

      validateForm(values);

      getReferralLinkMediaItemByUrlQuery({
        variables: {
          code: referalCode,
        },
        onCompleted: (data) => {
          setFieldValue(
            FieldName.ReferralCodeId,
            data.referralLinkMediaItemByCode.id,
            true,
          );
          setFieldValue(
            FieldName.MediaCampaignId,
            data.referralLinkMediaItemByCode.mediaCampaign.id,
          );
          setFieldValue(
            FieldName.MediaCampaignName,
            data.referralLinkMediaItemByCode.mediaCampaign.name,
          );
          setFieldValue(
            FieldName.MediaCampaignUrl,
            data.referralLinkMediaItemByCode.mediaCampaign.url,
          );
        },
        onError: () => {
          setFieldValue(FieldName.ReferralCodeId, undefined, true);

          toast.error('Такого реферального кода нет');
        },
      });
    };

    return {
      getReferralLinkByUrl,
      getReferralLinkByUrlLoading,
    };
  };
