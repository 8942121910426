import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { RewardTariffType } from 'generatedGraphql';
import { useSearchContext } from 'src/contexts/SearchContext';
import {
  GetRewardsVariables,
  useGetRewardsLazyQuery,
} from '../queries/generated/GetRewards';

const cellHeight = Heights.defaultCellHeight;

export const useGetRewards = (tariffType: RewardTariffType) => {
  const { searchValue } = useSearchContext();

  const baseVariables: GetRewardsVariables = {
    first: getCountByWindowHeight(cellHeight, 0),
    tariffType,
  };

  const [variables, setVariables] = useState(baseVariables);

  useEffect(() => {
    setVariables({
      ...baseVariables,
      search: searchValue,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tariffType]);

  const [loadRewards, { data, loading, error, fetchMore, refetch }] =
    useGetRewardsLazyQuery();

  const loadRewardsCallback = useCallback(
    () => loadRewards({ variables }),
    [loadRewards, variables],
  );

  const rewardsList = useMemo(
    () => data?.rewards?.edges.map(({ node }) => node),
    [data?.rewards?.edges],
  );

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: 10,
            after: data?.rewards?.pageInfo.endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [data?.rewards?.pageInfo.endCursor, fetchMore],
  );

  return {
    rewardsList,
    handleSearch: () =>
      loadRewards({
        variables: { ...variables, search: searchValue },
      }),
    loadRewards: loadRewardsCallback,
    loading,
    error,
    data,
    refetch,
    fetchMoreCallback,
    hasNextPage: data?.rewards?.pageInfo.hasNextPage,
  };
};
