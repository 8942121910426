import { FC } from 'react';
import { Tabs } from 'ui/Tabs';
import { MediaItemTabs, tabs } from './const';
import { useGetHandleChangeTab } from './hooks/useGetHandleChangeTab';

interface Props {
  currentTabId?: MediaItemTabs;
}

export const MediaItemsTabs: FC<Props> = ({ currentTabId }) => {
  const onChangeTab = useGetHandleChangeTab();

  return (
    <Tabs tabs={tabs} onChangeTab={onChangeTab} currentTabId={currentTabId} />
  );
};
