import {
  faArrowLeft,
  faCancel,
  faCheck,
  faCheckDouble,
  faClockRotateLeft,
  faCog,
  faFileExport,
  faFolderArrowUp,
  faPenLine,
  faPlus,
  faShare,
  faSlidersH,
  faSyncAlt,
  faTimes,
  faTrashCan,
  IconDefinition,
} from '@fortawesome/pro-solid-svg-icons';
import { ButtonType, HoverColor } from './const';

interface Value {
  icon: IconDefinition;
  hoverColor?: HoverColor;
}

export const getIconAndColorByType = (buttonType?: ButtonType): Value => {
  const getResult = ({ icon, hoverColor = HoverColor.BLUE }: Value): Value => ({
    icon,
    hoverColor,
  });

  switch (buttonType) {
    case ButtonType.Update:
      return getResult({ icon: faSyncAlt });
    case ButtonType.Add:
      return getResult({ icon: faPlus });
    case ButtonType.Edit:
      return getResult({ icon: faPenLine });
    case ButtonType.Check:
      return getResult({ icon: faCheck });
    case ButtonType.Close:
      return getResult({ icon: faTimes, hoverColor: HoverColor.RED });
    case ButtonType.Delete:
      return getResult({ icon: faTrashCan, hoverColor: HoverColor.RED });
    case ButtonType.GoBack:
      return getResult({ icon: faArrowLeft });
    case ButtonType.History:
      return getResult({ icon: faClockRotateLeft });
    case ButtonType.Share:
      return getResult({ icon: faShare });
    case ButtonType.Filter:
      return getResult({ icon: faSlidersH });
    case ButtonType.Archive:
      return getResult({ icon: faFolderArrowUp });
    case ButtonType.CheckDouble:
      return getResult({ icon: faCheckDouble });
    case ButtonType.Cancel:
      return getResult({ icon: faCancel, hoverColor: HoverColor.RED });
    case ButtonType.FileExport:
      return getResult({ icon: faFileExport });
    case ButtonType.Settings:
      return getResult({ icon: faCog });
    default:
      return getResult({ icon: faSyncAlt });
  }
};
