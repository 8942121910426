import { useCallback, useMemo } from 'react';
import { getCountByWindowHeight, Heights } from 'utils/getCountByWindowHeight';
import { useGetPlayerAccountsLazyQuery } from '../queries/generated/GetPlayerAccounts';

interface Params {
  playerId: string;
  isFullScreenTable: boolean;
}

const COUNT_PER_PAGE = 10;
const cellHeight = Heights.defaultCellHeight;

export const useGetPlayerAccounts = <T>({
  playerId,
  isFullScreenTable,
}: Params) => {
  const countPerPageByTableSize = isFullScreenTable
    ? getCountByWindowHeight(cellHeight)
    : COUNT_PER_PAGE;

  const [loadAccounts, { data, loading, error, refetch, fetchMore }] =
    useGetPlayerAccountsLazyQuery({
      variables: {
        playerId,
        first: countPerPageByTableSize,
      },
    });

  const accounts = data?.player.accountsRevised;
  const hasNextPage = accounts?.pageInfo.hasNextPage;
  const endCursor = accounts?.pageInfo.endCursor;

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore({
        variables: {
          playerId,
          first: COUNT_PER_PAGE,
          after: endCursor,
        },
      }),
    [fetchMore, playerId, endCursor],
  );

  const accountsList = useMemo(
    () =>
      data?.player.accountsRevised.edges.map(
        ({ node }) => node,
      ) as unknown as Array<T>,
    [data],
  );

  return {
    data,
    accountsList,
    loading,
    error,
    refetch,
    loadAccounts,
    fetchMoreCallback,
    hasNextPage,
  };
};
