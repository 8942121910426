import { AccountsHistory } from 'src/pages/Player/pages/AccountsHistory';
import { Affiliates } from '../pages/Company/pages/Affiliates';
import { CompaniesList } from '../pages/CompaniesList';
import { Player } from '../pages/Player';
import { LogIn, Password, UserPrivilege } from '../components/Auth';
import { Withdrawals } from '../pages/Withdrawals';
import { PlayersList } from '../pages/PlayersList';
import AdminsList from '../pages/AdminsList';
import TwoFactor from '../components/Auth/components/TwoFactor/TwoFactor';
import { PrivilegeAccessType, RouteType } from './types';
import { Route } from './routes.const';
import { Deposits } from '../pages/Player/pages/Deposits';
import { Freespins } from '../pages/Player/pages/Freespins';
import { Bonuses } from '../pages/Player/pages/Bonuses';
import { Messages } from '../pages/Player/pages/Messages';
import { Transfers } from '../pages/Player/pages/Transfers';
import { Withdrawals as PlayerWithdrawals } from '../pages/Player/pages/Withdrawals';
import { Documents } from '../pages/Player/pages/Documents';
import { Restrictions } from '../pages/Player/pages/Restrictions';
import { Partner } from '../pages/Partner';
import PartnersList from '../pages/PartnersList';
import { SignInRestrictionHistory } from '../pages/Player/pages/SignInRestrictionHistory';
import { TournamentRebuys } from '../pages/Player/pages/TournamentRebuys';
import { RewardTariffs } from '../pages/RewardTariffs';
import { PartnerApplications } from '../pages/PartnerApplications';
import { MediaCampaigns } from '../pages/MediaCampaigns';
import { CompanyApplications } from '../pages/CompanyApplications';
import { Company } from '../pages/Company';
import { CompanyAffiliateApplications } from '../pages/CompanyAffiliateApplications';
import { CompanyMediaItems } from '../pages/Company/pages/CompanyMediaItems';
import { PartnerMediaItems } from '../pages/Partner/pages/PartnerMediaItems';
import { QualifiedReferralsList } from '../pages/QualifiedReferralsList';
import { PartnerMediaCampaigns } from '../pages/Partner/pages/PartnerMediaCampaigns';
import { CompanyMediaCampaigns } from '../pages/Company/pages/CompanyMediaCampaigns';
import { Statistic } from '../pages/Statistic';
import { CpaPartnerReport } from '../pages/Statistic/pages/CpaPartnerReport';
import { RevSharePartnerReport } from '../pages/Statistic/pages/RevSharePartnerReport';
import { CpaReferralLinkReport } from '../pages/Statistic/pages/CpaReferralLinkReport';
import { RevShareReferralLinkReport } from '../pages/Statistic/pages/RevShareReferralLinkReport';
import { OutgoingMoneyTransfers as CompanyOutgoingMoneyTransfers } from '../pages/Company/pages/OutgoingMoneyTransfers';
import { OutgoingMoneyTransfers as PartnerOutgoingMoneyTransfers } from '../pages/Partner/pages/OutgoingMoneyTransfers';
import { RevShareCompanyPartnersReport } from '../pages/Statistic/pages/RevShareCompanyPartnersReport';
import { CpaCompanyPartnersReport } from '../pages/Statistic/pages/CpaCompanyPartnersReport';
import { RevShareCompanyReport } from '../pages/Statistic/pages/RevShareCompanyReport';
import { CpaCompanyReport } from '../pages/Statistic/pages/CpaCompanyReport';
import { RevShareCompanyAffiliateReport } from '../pages/Statistic/pages/RevShareCompanyAffiliateReport';
import { CpaCompanyAffiliateReport } from '../pages/Statistic/pages/CpaCompanyAffiliateReport';
import { QualifiedReferralConfirmationSettings } from '../pages/QualifiedReferralConfirmationSettings';
import { ExternalMoneyTransfers } from '../pages/Player/pages/ExternalMoneyTransfers';
import { PartnerRewardTariffs } from '../pages/Partner/pages/PartnerRewardTariffs';
import { CompanyRewardTariffs } from '../pages/Company/pages/CompanyRewardTariffs';
import { MediaItemsReferralLink } from '../pages/MediaItems/components/MediaItemsReferralLink';
import { MediaItemsPromoCode } from '../pages/MediaItems/components/MediaItemsPromoCode';
import { RevShareRewards } from '../pages/Rewards/components/RevShareRewards';
import { CpaRewards } from '../pages/Rewards/components/CpaRewards';
import { Accounts } from '../pages/Accounts';
import { Accounts as PlayerAccounts } from '../pages/Player/pages/Accounts';
import { MediaItemsPromoCodeActivations } from '../pages/MediaItems/components/MediaItemsPromoCodeActivations';

const privateRoutes: Array<RouteType> = [
  {
    path: Route.ADMINS,
    element: <AdminsList />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewAdmin],
  },
  {
    path: Route.ACCOUNTS,
    element: <Accounts />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewAccount],
  },
  {
    path: Route.PARTNER,
    element: <Partner />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewPartner],
  },
  {
    path: Route.PARTNER_MEDIA_ITEMS,
    element: <PartnerMediaItems />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewPartnerReferralLinkMediaItem],
  },
  {
    path: Route.PARTNERS,
    element: <PartnersList />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewPartner],
  },
  {
    path: Route.PARTNER_APPLICATIONS,
    element: <PartnerApplications />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewPartnerApplication],
  },
  {
    path: Route.PARTNER_MEDIA_CAMPAIGNS,
    element: <PartnerMediaCampaigns />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewPartnerMediaCampaign],
  },
  {
    path: Route.PARTNER_OUTGOING_MONEY_TRANSFERS,
    element: <PartnerOutgoingMoneyTransfers />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewPartnerOutgoingMoneyTransfer],
  },
  {
    path: Route.PARTNER_REWARD_TARIFFS,
    element: <PartnerRewardTariffs />,
    accessType: PrivilegeAccessType.All,
    privileges: [
      UserPrivilege.ViewPartnerRevShareRewardTariff,
      UserPrivilege.ViewPartnerCpaRewardTariff,
    ],
  },
  {
    path: Route.REWARD_TARIFFS,
    element: <RewardTariffs />,
    accessType: PrivilegeAccessType.All,
    privileges: [
      UserPrivilege.ViewRevShareRewardTariff,
      UserPrivilege.ViewCpaRewardTariff,
    ],
  },
  {
    path: Route.REFERRAL_LINK_MEDIA_ITEMS,
    element: <MediaItemsReferralLink />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewMediaItem],
  },
  {
    path: Route.PROMO_CODE_MEDIA_ITEMS,
    element: <MediaItemsPromoCode />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewMediaItem],
  },
  {
    path: Route.PROMO_CODE_MEDIA_ITEM_ACTIVATIONS,
    element: <MediaItemsPromoCodeActivations />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewPromoCodeMediaItemActivation],
  },
  {
    path: Route.PLAYER,
    element: <Player />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewPlayer],
  },
  {
    path: Route.PLAYERS,
    element: <PlayersList />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewPlayer],
  },
  {
    path: Route.PLAYER_BONUSES,
    element: <Bonuses />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewPlayerBonus],
  },
  {
    path: Route.PLAYER_DEPOSITS,
    element: <Deposits />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewPlayerDeposit],
  },
  {
    path: Route.PLAYER_ACCOUNTS_HISTORY,
    element: <AccountsHistory />,
    accessType: PrivilegeAccessType.All,
    privileges: [
      UserPrivilege.ViewPlayerAccount,
      UserPrivilege.ViewAccountEventgroup,
    ],
  },
  {
    path: Route.PLAYER_ACCOUNTS,
    element: <PlayerAccounts />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewPlayerAccount],
  },
  {
    path: Route.PLAYER_DOCUMENTS,
    element: <Documents />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewPlayerDocument],
  },
  {
    path: Route.PLAYER_MESSAGES,
    element: <Messages />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewPlayerMessage],
  },
  {
    path: Route.PLAYER_RESTRICTIONS,
    element: <Restrictions />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewPlayerRestriction],
  },
  {
    path: Route.PLAYER_SIGN_IN_RESTRICTION_HISTORY,
    element: <SignInRestrictionHistory />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewPlayerSignInRestriction],
  },
  {
    path: Route.PLAYER_FREESPINS,
    element: <Freespins />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewPlayerFreespin],
  },
  {
    path: Route.WITHDRAWAL,
    element: <Withdrawals />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewWithdrawal],
  },
  {
    path: Route.PLAYER_TRANSFERS,
    element: <Transfers />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewPlayerMoneytransfer],
  },
  {
    path: Route.PLAYER_TOURNAMENT_REBUY,
    element: <TournamentRebuys />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewPlayerTournamentrebuy],
  },
  {
    path: Route.PLAYER_WITHDRAWALS,
    element: <PlayerWithdrawals />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewPlayerWithdrawal],
  },
  {
    path: Route.MEDIA_CAMPAIGNS,
    element: <MediaCampaigns />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewMediaCampaign],
  },
  {
    path: Route.COMPANIES,
    element: <CompaniesList />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewCompany],
  },
  {
    path: Route.COMPANY_APPLICATIONS,
    element: <CompanyApplications />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewCompanyApplication],
  },
  {
    path: Route.COMPANY_AFFILIATES,
    element: <Affiliates />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewCompanyAffiliate],
  },
  {
    path: Route.COMPANY_MEDIA_CAMPAIGNS,
    element: <CompanyMediaCampaigns />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewCompanyMediaCampaign],
  },
  {
    path: Route.COMPANY,
    element: <Company />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewCompany],
  },
  {
    path: Route.COMPANY_AFFILIATE_APPLICATIONS,
    element: <CompanyAffiliateApplications />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewCompanyAffiliateApplication],
  },
  {
    path: Route.COMPANY_MEDIA_ITEMS,
    element: <CompanyMediaItems />,
    accessType: PrivilegeAccessType.All,
    privileges: [
      UserPrivilege.ViewCompanyReferralLinkMediaItem,
      UserPrivilege.ViewCompanyPromoCodeMediaItem,
    ],
  },
  {
    path: Route.COMPANY_REWARD_TARIFFS,
    element: <CompanyRewardTariffs />,
    accessType: PrivilegeAccessType.All,
    privileges: [
      UserPrivilege.ViewCompanyRevShareRewardTariff,
      UserPrivilege.ViewCompanyCpaRewardTariff,
    ],
  },
  {
    path: Route.QUALIFIED_REFERRALS,
    element: <QualifiedReferralsList />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewQualifiedReferral],
  },
  {
    path: Route.REWARDS_CPA,
    element: <CpaRewards />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewReward],
  },
  {
    path: Route.REWARDS_REV_SHARE,
    element: <RevShareRewards />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewReward],
  },
  {
    path: Route.STATISTIC,
    element: <Statistic />,
    accessType: PrivilegeAccessType.All,
    privileges: [
      UserPrivilege.ViewCpaGeneralReport,
      UserPrivilege.ViewRevShareGeneralReport,
    ],
  },
  {
    path: Route.STATISTIC_CPA_PARTNER,
    element: <CpaPartnerReport />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewPartnerCpaReport],
  },
  {
    path: Route.STATISTIC_REV_SHARE_PARTNER,
    element: <RevSharePartnerReport />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewPartnerRevShareReport],
  },
  {
    path: Route.STATISTIC_REV_SHARE_COMPANY_PARTNERS,
    element: <RevShareCompanyPartnersReport />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewCompanyRevShareCompanyPartnerReport],
  },
  {
    path: Route.STATISTIC_CPA_COMPANY_PARTNERS,
    element: <CpaCompanyPartnersReport />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewCompanyCpaCompanyPartnerReport],
  },
  {
    path: Route.STATISTIC_REV_SHARE_COMPANY,
    element: <RevShareCompanyReport />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewCompanyRevShareReport],
  },
  {
    path: Route.STATISTIC_CPA_COMPANY,
    element: <CpaCompanyReport />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewCompanyCpaReport],
  },
  {
    path: Route.STATISTIC_REV_SHARE_COMPANY_AFFILIATE,
    element: <RevShareCompanyAffiliateReport />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewCompanyAffiliateRevShareReport],
  },
  {
    path: Route.STATISTIC_CPA_COMPANY_AFFILIATE,
    element: <CpaCompanyAffiliateReport />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewCompanyAffiliateCpaReport],
  },
  {
    path: Route.STATISTIC_CPA_REFERRAL_LINK,
    element: <CpaReferralLinkReport />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewReferralLinkMediaItemCpaReport],
  },
  {
    path: Route.STATISTIC_REV_SHARE_REFERRAL_LINK,
    element: <RevShareReferralLinkReport />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewReferralLinkMediaItemRevShareReport],
  },
  {
    path: Route.COMPANY_OUTGOING_MONEY_TRANSFERS,
    element: <CompanyOutgoingMoneyTransfers />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewCompanyOutgoingMoneyTransfer],
  },
  {
    path: Route.PLAYER_EXTERNAL_MONEY_TRANSFERS,
    element: <ExternalMoneyTransfers />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewPlayerExternalMoneyTransfer],
  },
  {
    path: Route.QUALIFIED_REFERRAL_CONFIRMATION_SETTINGS,
    element: <QualifiedReferralConfirmationSettings />,
    accessType: PrivilegeAccessType.All,
    privileges: [UserPrivilege.ViewQualifiedReferralConfirmationSetting],
  },
];

const publicRoutes: Array<RouteType> = [
  { path: Route.LOGIN, element: <LogIn /> },
  { path: Route.PASSWORD, element: <Password /> },
  { path: Route.TWO_FACTOR, element: <TwoFactor /> },
];

export const useRoutes = (): {
  privateRoutes: Array<RouteType>;
  publicRoutes: Array<RouteType>;
} => ({
  privateRoutes,
  publicRoutes,
});
