import { nbsp } from 'src/const';

export enum CreatePromoCodeModalSteps {
  BaseStep = 1,
  ConnectionWithFreespinsAndBonus = 2,
  ConfirmCreation = 3,
}

export enum FieldName {
  ActivationLimit = 'activationLimit',
  Token = 'token',
  ReferralLink = 'referralLink',
  ReferralCodeId = 'referralCodeId',
  MediaCampaignId = 'mediaCampaignId',
  MediaCampaignName = 'mediaCampaignName',
  MediaCampaignUrl = 'mediaCampaignUrl',
  Usage = 'usage',

  FreespinGameName = 'freespinGameName',
  FreespinGameId = 'freespinGameId',
  FreespinCurrency = 'freespinCurrency',
  FreespinCount = 'freespinCount',
  FreespinWager = 'freespinWager',
  FreespinMinBalance = 'freespinMinBalance',

  BonusAmount = 'bonusAmount',
  BonusCurrency = 'bonusCurrency',
  BonusWager = 'bonusWager',
  BonusCashout = 'bonusCashout',
  BonusMinBalance = 'bonusMinBalance',

  ConnectionWithReferralLink = 'connectionWithReferralLink',
  ConnectionWithFreespins = 'connectionWithFreespins',
  ConnectionWithBonus = 'connectionWithBonus',
  InvalidToken = 'invalidToken',
}

export const mapCreatePromoCodeFieldToTitle: {
  [key in FieldName]: string;
} = {
  [FieldName.ActivationLimit]: 'Количество активаций',
  [FieldName.Token]: 'Токен',
  [FieldName.ReferralLink]: 'Реферальный код',
  [FieldName.ReferralCodeId]: 'Id реферального кода',
  [FieldName.MediaCampaignId]: 'Медиакампания',
  [FieldName.MediaCampaignName]: 'Название медиакампании',
  [FieldName.MediaCampaignUrl]: 'URL медиакампании',
  [FieldName.Usage]: 'Использование',

  [FieldName.FreespinGameId]: 'Слот',
  [FieldName.FreespinCurrency]: 'Валюта',
  [FieldName.FreespinCount]: 'Количество',
  [FieldName.FreespinWager]: 'Вейджер',
  [FieldName.FreespinMinBalance]: 'Минимальный баланс',
  [FieldName.FreespinGameName]: 'Название игры',

  [FieldName.BonusAmount]: 'Сумма',
  [FieldName.BonusWager]: 'Вейджер',
  [FieldName.BonusCashout]: 'Кэшаут',
  [FieldName.BonusMinBalance]: 'Минимальный баланс',
  [FieldName.BonusCurrency]: 'Валюта',

  [FieldName.ConnectionWithReferralLink]: `Связать с${nbsp}рефссылкой`,
  [FieldName.ConnectionWithFreespins]: `Связь с${nbsp}фриспинами`,
  [FieldName.ConnectionWithBonus]: `Связь с${nbsp}бонусом`,
  [FieldName.InvalidToken]: `${nbsp}`,
};
