export enum ColumnId {
  Id = 'id',
  Name = 'name',
  MediaCampaign = 'mediaCampaign',
  ReferralLink = 'referralLink',
  PostbackCount = 'postbackCount',
  RewardTariff = 'mediaItemRewardTariff.rewardTariff.title',
  CreatedAt = 'createdAt',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.Id]: 'ID',
  [ColumnId.Name]: 'Название',
  [ColumnId.MediaCampaign]: 'Медиакампания',
  [ColumnId.ReferralLink]: 'Рефссылка',
  [ColumnId.PostbackCount]: 'Постбэк',
  [ColumnId.RewardTariff]: 'Партнёрский тариф',
  [ColumnId.CreatedAt]: 'Создана',
};

export const blockColumnsIds = [
  ColumnId.Name,
  ColumnId.MediaCampaign,
  ColumnId.ReferralLink,
  ColumnId.RewardTariff,
  ColumnId.CreatedAt,
];

export const pageColumnsIds = [
  ColumnId.Id,
  ColumnId.Name,
  ColumnId.MediaCampaign,
  ColumnId.ReferralLink,
  ColumnId.PostbackCount,
  ColumnId.RewardTariff,
  ColumnId.CreatedAt,
];
