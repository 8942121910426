import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type AcceptCompanyApplicationVariables = SchemaTypes.Exact<{
  input: SchemaTypes.AcceptCompanyApplicationInput;
}>;


export type AcceptCompanyApplication = { __typename: 'Mutation', acceptCompanyApplication: { __typename: 'AcceptCompanyApplicationPayload', companyApplication: { __typename: 'CompanyApplication', id: string, status: SchemaTypes.CompanyApplicationStatus, updatedAt: string } } };


export const AcceptCompanyApplicationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AcceptCompanyApplication"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AcceptCompanyApplicationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"acceptCompanyApplication"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"companyApplication"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}}]}}]}}]}}]} as unknown as DocumentNode;
export type AcceptCompanyApplicationMutationFn = Apollo.MutationFunction<AcceptCompanyApplication, AcceptCompanyApplicationVariables>;

/**
 * __useAcceptCompanyApplication__
 *
 * To run a mutation, you first call `useAcceptCompanyApplication` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptCompanyApplication` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptCompanyApplication, { data, loading, error }] = useAcceptCompanyApplication({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptCompanyApplication(baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptCompanyApplication, AcceptCompanyApplicationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AcceptCompanyApplication, AcceptCompanyApplicationVariables>(AcceptCompanyApplicationDocument, options);
      }
export type AcceptCompanyApplicationHookResult = ReturnType<typeof useAcceptCompanyApplication>;
export type AcceptCompanyApplicationMutationResult = Apollo.MutationResult<AcceptCompanyApplication>;
export type AcceptCompanyApplicationMutationOptions = Apollo.BaseMutationOptions<AcceptCompanyApplication, AcceptCompanyApplicationVariables>;