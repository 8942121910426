import { FC } from 'react';
import BlockTable from 'ui/Block/BlockTable';
import { MediaItemPromoCodeBasicData } from 'src/queries/generated/MediaItemPromoCodeBasicData';
import { useOpenMediaItemPromoCodeModal } from '../../../../hooks/useOpenMediaItemPromoCodeModal';
import { useGetMediaItemsPromoCodesColumns } from './hooks/useGetMediaItemsPromoCodesColumns';

interface Props {
  mediaItemsPromoCode?: Array<MediaItemPromoCodeBasicData>;
}

export const MediaItemsPromoCodeTable: FC<Props> = ({
  mediaItemsPromoCode,
}) => {
  const { columns } = useGetMediaItemsPromoCodesColumns();
  const openModal = useOpenMediaItemPromoCodeModal();

  return (
    <BlockTable
      onClickOnRow={({ id }, initiatorId) =>
        openModal({
          promoCodeId: id,
          initiatorId,
        })
      }
      columns={columns}
      data={mediaItemsPromoCode}
      isFullScreenTable
    />
  );
};
