export enum PageTypeStorage {
  AdminPage = '@adminPageStorage',
  PlayerPage = '@playerPageStorage',
  RevShareCompanyReport = '@revShareCompanyReportPageStorage',
  CpaCompanyReport = '@cpaCompanyReportPageStorage',
  RevShareCompanyAffiliateReport = '@revShareCompanyAffiliateReportPageStorage',
  CpaCompanyAffiliateReport = '@cpaCompanyAffiliateReportPageStorage',
  RevShareReferralLinkReport = '@revShareReferralLinkReportPageStorage',
  CPAReferralLinkReport = '@cpaReferralLinkReportPageStorage',
  CPAPartnerReport = '@cpaPartnerReportPageStorage',
  RevSharePartnerReport = '@revShareReportPageStorage',
  PartnerPage = '@partnerPageStorage',
  CompanyApplicationsPage = '@companyApplicationsPageStorage',
  PartnerApplicationsPage = '@partnerApplicationsPageStorage',
  CompanyPage = '@companyPageStorage',
  WithdrawalPage = '@withdrawalPageStorage',
  GeneralReport = '@generalReportPageStorage',
  QualifiedReferralsPage = '@qualifiedReferralsPageStorage',
  MediaItemsReferralLink = '@mediaItemsReferralLinkStorage',
  MediaItemsPromoCode = '@mediaItemsPromoCodeStorage',
  MediaCampaignsPage = '@mediaCampaignsPageStorage',
  CompanyAffiliateApplicationsPage = '@companyAffiliateApplicationsPageStorage',
  RevShareReward = '@revShareRewardPageStorage',
  CpaReward = '@cpaRewardPageStorage',
}
