import { useContext } from 'react';
import { BlockContext } from 'ui/Block/store';
import Block from 'ui/Block';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { nbsp } from 'src/const';
import BlockTable from 'ui/Block/BlockTable';
import { ColumnDef } from '@tanstack/react-table';
import { useTournamentRebuyModal } from '../../hooks/useTournamentRebuyModal';
import { TournamentRebuysBasicData } from '../../queries/generated/TournamentRebuysBasicData';
import { TournamentRebuysData } from '../../queries/generated/TournamentRebuysData';
import { useGetTournamentRebuys } from '../../hooks/useGetTournamentRebuys';

interface Props<T> {
  playerId: string;
  columns: Array<ColumnDef<T>>;
  shouldReturnToPrevPage: boolean;
  isFullScreenTable: boolean;
  routeToCustomPage?: string;
}

export const TournamentRebuysTable = <T extends { __typename: string }>({
  playerId,
  columns,
  shouldReturnToPrevPage,
  isFullScreenTable,
  routeToCustomPage,
}: Props<T>) => {
  const { state } = useContext(BlockContext);

  const {
    tournamentRebuysData,
    tournamentRebuys,
    tournamentRebuysError,
    tournamentRebuysLoading,
    tournamentRebuysFetchMore,
    tournamentRebuysRefetch,
  } = useGetTournamentRebuys({
    isFullScreenTable,
    playerId,
  });

  useBlockComponentState({
    loading: tournamentRebuysLoading,
    error: tournamentRebuysError,
  });

  useBlockTableComponentState({
    data: tournamentRebuysData,
    fetchMoreCallback: tournamentRebuysFetchMore,
    refetch: tournamentRebuysRefetch,
    loading: tournamentRebuysLoading,
    hasNextPage: tournamentRebuysData?.pageInfo.hasNextPage,
    isFullScreenTable,
  });

  const openTournamentRebuysModal = useTournamentRebuyModal();

  const handleRowClick = (
    tournamentRebuy: TournamentRebuysBasicData | TournamentRebuysData,
    initiatorId: string,
  ) => {
    openTournamentRebuysModal({
      tournamentRebuy,
      initiatorId,
    });
  };

  return (
    <Block
      title={`Докупы в${nbsp}турнирах`}
      id="playerTournamentRebuys"
      headerContent={
        <ActionButton
          actionType={ButtonType.Update}
          hidden={!state.isOpened}
          disabled={state.isLoading}
          onClick={() => tournamentRebuysRefetch()}
        />
      }
      shrinkLoaderWrapper={false}
      emptyText="Игрок не совершал операций"
      isEmpty={isListEmpty(tournamentRebuys)}
      shouldReturnToPrevPage={shouldReturnToPrevPage}
    >
      {hasList(tournamentRebuys) && (
        <BlockTable
          onClickOnRow={handleRowClick}
          // TODO: tech ticket https://eyeconweb.atlassian.net/browse/APN-1651
          columns={
            columns as Array<TournamentRebuysBasicData | TournamentRebuysData>
          }
          data={tournamentRebuys}
          routeToCustomPage={routeToCustomPage}
          isFullScreenTable={isFullScreenTable}
        />
      )}
    </Block>
  );
};
