import { Form, Formik, FormikHelpers } from 'formik';
import { FC } from 'react';
import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import { Dropdown } from 'ui/Dropdown';
import { Tooltip } from 'ui/Tooltip';
import { useModalManageData } from 'ui/Modal';
import { RewardTariffType } from 'commonTypes';
import Input from 'ui/Input';
import { MediaCampaignDropdown } from 'commonComponents/MediaCampaignDropdown';
import FormRow from 'ui/FormRow';
import { RadioButton } from 'ui/RadioButton';
import { CompaniesDropdown } from 'commonComponents/CompaniesDropdown';
import { PartnersDropdown } from 'commonComponents/PartnersDropdown';
import { useGenerateReferralCode } from 'src/utils/hooks/useGenerateReferralCode';
import { rewardTypeOptions } from 'src/helpers';
import { CompanyAffiliatesDropdown } from 'src/components/CompanyAffiliatesDropdown';
import styles from './CreateMediaItemReferralLink.module.scss';
import { useCreateMediaItemReferralLink } from './hooks/useCreateMediaItemReferralLink';
import { validate } from './validation';
import { CreateMediaItemReferralLinkValues } from '../../types';
import { CpaForm } from './components/CpaForm';
import { RevShareForm } from './components/RevShareForm';
import { AffiliateType } from '../../const';
import { CreateMediaItemLinkFields } from './const';
import { useGetInitialAffiliateTypeByPrivileges } from './hooks/useGetInitialAffiliateTypeByPrivileges';
import { useGetCreateMediaItemPrivileges } from '../../../../hooks/useGetCreateMediaItemPrivileges';

export const CreateMediaItemReferralLink: FC = () => {
  const {
    canCreatePartnerReferralLink,
    canCreateCompanyReferralLink,
    canCreateCompanyAffiliateReferralLink,
  } = useGetCreateMediaItemPrivileges();

  const { initialAffiliateType } = useGetInitialAffiliateTypeByPrivileges({
    canCreatePartnerReferralLink,
    canCreateCompanyReferralLink,
  });

  const {
    createMediaItemReferralLink,
    createReferralLinkMediaItemError,
    createReferralLinkMediaItemDate,
    createReferralLinkMediaItemLoading,
  } = useCreateMediaItemReferralLink();

  const { referralCodeLoading, referralCode } = useGenerateReferralCode();

  useModalManageData({
    loading: createReferralLinkMediaItemLoading,
    errorConfig: {
      error: createReferralLinkMediaItemError,
      toastId: 'create-tariffs-error',
    },
    hasData: !!createReferralLinkMediaItemDate,
  });

  const initialValues: CreateMediaItemReferralLinkValues = {
    [CreateMediaItemLinkFields.typeRewardTariff]: null,
    [CreateMediaItemLinkFields.name]: '',
    [CreateMediaItemLinkFields.partnerId]: '',
    [CreateMediaItemLinkFields.companyId]: '',
    [CreateMediaItemLinkFields.companyAffiliateId]: '',
    [CreateMediaItemLinkFields.mediaCampaignId]: '',
    [CreateMediaItemLinkFields.code]: referralCode || null,
    [CreateMediaItemLinkFields.cpaRewardTariffId]: '',
    [CreateMediaItemLinkFields.fallbackTariffId]: '',
    [CreateMediaItemLinkFields.revShareRewardTariffId]: '',
    [CreateMediaItemLinkFields.affiliateType]: initialAffiliateType,
  };

  const resetTariff = (
    values: CreateMediaItemReferralLinkValues,
    setFieldValue: FormikHelpers<typeof initialValues>['setFieldValue'],
  ) => {
    const clearCpaTariffData = () => {
      setFieldValue(CreateMediaItemLinkFields.revShareRewardTariffId, '');
    };

    const clearRevShareTariffData = () => {
      setFieldValue(CreateMediaItemLinkFields.cpaRewardTariffId, '');
      setFieldValue(CreateMediaItemLinkFields.fallbackTariffId, '');
    };

    if (values.typeRewardTariff === RewardTariffType.Cpa) {
      clearCpaTariffData();
    } else if (values.typeRewardTariff === RewardTariffType.RevShare) {
      clearRevShareTariffData();
    }

    if (!values.typeRewardTariff) {
      clearRevShareTariffData();
      clearCpaTariffData();
    }
  };

  const resetAffiliate = (
    setFieldValue: FormikHelpers<typeof initialValues>['setFieldValue'],
  ) => {
    setFieldValue(CreateMediaItemLinkFields.partnerId, '');
    setFieldValue(CreateMediaItemLinkFields.companyId, '');
    setFieldValue(CreateMediaItemLinkFields.companyAffiliateId, '');
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={createMediaItemReferralLink}
      enableReinitialize
      validate={validate}
      validateOnChange={false}
    >
      {({ isSubmitting, isValid, dirty, values, setFieldValue }) => (
        <Form>
          <FormRowsWrapper>
            <FormRow isWide>
              <Input
                name={CreateMediaItemLinkFields.name}
                placeholder="Название"
                label="Название"
              />
            </FormRow>
            <FormRow isWide>
              <MediaCampaignDropdown
                name={CreateMediaItemLinkFields.mediaCampaignId}
                label="Медиакампания"
              />
            </FormRow>
            <FormRow isWide>
              <Input
                name={CreateMediaItemLinkFields.code}
                label="Реферальный код"
              />
            </FormRow>
            <FormRow isWide>
              <Dropdown
                onChange={() => {
                  resetTariff(values, setFieldValue);
                }}
                name={CreateMediaItemLinkFields.typeRewardTariff}
                label="Модель оплаты"
                options={rewardTypeOptions}
              />
            </FormRow>
            {values.typeRewardTariff === RewardTariffType.Cpa && <CpaForm />}
            {values.typeRewardTariff === RewardTariffType.RevShare && (
              <RevShareForm />
            )}
            <div className={styles.titleCheckbox}>Тип аккаунта</div>
            <FormRow isWide>
              <RadioButton
                onChange={() => resetAffiliate(setFieldValue)}
                name={CreateMediaItemLinkFields.affiliateType}
                value={AffiliateType.Partner}
                disabled={!canCreatePartnerReferralLink}
              >
                Партнёр
              </RadioButton>
            </FormRow>
            <FormRow isWide>
              <RadioButton
                onChange={() => resetAffiliate(setFieldValue)}
                name={CreateMediaItemLinkFields.affiliateType}
                value={AffiliateType.Company}
                disabled={!canCreateCompanyReferralLink}
              >
                Компания
              </RadioButton>
            </FormRow>
            {values.affiliateType === AffiliateType.Partner && (
              <FormRow isWide>
                <PartnersDropdown
                  label="Партнёр"
                  name={CreateMediaItemLinkFields.partnerId}
                />
              </FormRow>
            )}
            {values.affiliateType === AffiliateType.Company && (
              <FormRow isWide>
                <CompaniesDropdown
                  label="Компания"
                  name={CreateMediaItemLinkFields.companyId}
                />
              </FormRow>
            )}
            {values.affiliateType === AffiliateType.Company &&
              values.companyId && (
                <FormRow isWide>
                  <CompanyAffiliatesDropdown
                    companyId={values.companyId}
                    label="Партнёр компании"
                    name={CreateMediaItemLinkFields.companyAffiliateId}
                    disabled={!canCreateCompanyAffiliateReferralLink}
                  />
                </FormRow>
              )}
          </FormRowsWrapper>
          <Tooltip
            tooltipContent={
              !isValid || !dirty ? 'Заполните все поля' : undefined
            }
            placement="right"
          >
            <Button
              size={ButtonSize.Large}
              theme={ButtonTheme.Process}
              type="submit"
              isLoading={isSubmitting || referralCodeLoading}
              disabled={!isValid || !dirty}
            >
              Создать
            </Button>
          </Tooltip>
        </Form>
      )}
    </Formik>
  );
};
