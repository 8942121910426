import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { PlayerAccountHistoryData } from '../fragments/generated/PlayerAccountHistoryData';
import { SumData } from '../../../../../../queries/generated/SumData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetPlayerAccountsHistoryVariables = SchemaTypes.Exact<{
  playerId: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetPlayerAccountsHistory = { __typename: 'Query', player: { __typename: 'Player', id: string, accounts: Array<{ __typename: 'Account', id: string, balance: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, bonusBalance?: { __typename: 'Sum', amount: number, currency: CommonType.Currency } | null }> } };


export const GetPlayerAccountsHistoryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPlayerAccountsHistory"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"playerId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"player"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"playerId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"accounts"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Account"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PlayerAccountHistoryData"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}},...PlayerAccountHistoryData.definitions,...SumData.definitions]} as unknown as DocumentNode;

/**
 * __useGetPlayerAccountsHistory__
 *
 * To run a query within a React component, call `useGetPlayerAccountsHistory` and pass it any options that fit your needs.
 * When your component renders, `useGetPlayerAccountsHistory` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlayerAccountsHistory({
 *   variables: {
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function useGetPlayerAccountsHistory(baseOptions: ApolloReactHooks.QueryHookOptions<GetPlayerAccountsHistory, GetPlayerAccountsHistoryVariables> & ({ variables: GetPlayerAccountsHistoryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetPlayerAccountsHistory, GetPlayerAccountsHistoryVariables>(GetPlayerAccountsHistoryDocument, options);
      }
export function useGetPlayerAccountsHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPlayerAccountsHistory, GetPlayerAccountsHistoryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetPlayerAccountsHistory, GetPlayerAccountsHistoryVariables>(GetPlayerAccountsHistoryDocument, options);
        }
export function useGetPlayerAccountsHistorySuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<GetPlayerAccountsHistory, GetPlayerAccountsHistoryVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetPlayerAccountsHistory, GetPlayerAccountsHistoryVariables>(GetPlayerAccountsHistoryDocument, options);
        }
export type GetPlayerAccountsHistoryHookResult = ReturnType<typeof useGetPlayerAccountsHistory>;
export type GetPlayerAccountsHistoryLazyQueryHookResult = ReturnType<typeof useGetPlayerAccountsHistoryLazyQuery>;
export type GetPlayerAccountsHistorySuspenseQueryHookResult = ReturnType<typeof useGetPlayerAccountsHistorySuspenseQuery>;
export type GetPlayerAccountsHistoryQueryResult = Apollo.QueryResult<GetPlayerAccountsHistory, GetPlayerAccountsHistoryVariables>;