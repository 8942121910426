import { FC } from 'react';
import Error from 'ui/Error';
import { MessageBlockWithButton } from 'commonComponents/MessageBlockWithButton';
import { ModalItemsList } from 'ui/Modal/components/ModalItemsList';
import { useUpdateModalStep } from 'ui/Modal/hooks/useUpdateModalStep';
import { UserPrivilege } from 'generatedUserPrivilege';
import { useAuth } from 'commonComponents/Auth';
import { useGetAffiliatePromoCodes } from './hooks/useGetAffiliatePromoCodes';
import { AffiliatePromoCodeItem } from '../AffiliatePromoCodeItem';
import { AffiliateMediaItemsSteps } from '../AffiliatePromoCodeItem/const';

interface Props {
  companyId: string;
  companyAffiliateId: string;
  onDetailsClick: (currentPromoCodeId: string) => void;
}

export const AffiliatePromoCodesList: FC<Props> = ({
  companyId,
  companyAffiliateId,
  onDetailsClick,
}) => {
  const auth = useAuth();
  const { promoCodes, loading, error, loadMore, isListEmpty } =
    useGetAffiliatePromoCodes({
      companyId,
      companyAffiliateId,
    });

  const { updateModalStep } = useUpdateModalStep<AffiliateMediaItemsSteps>();

  if (error) {
    return <Error error={error} />;
  }

  const hasPrivilegeToCreatePromoCode = auth.privileges.areEveryGranted({
    privileges: [UserPrivilege.CreateCompanyAffiliatePromoCodeMediaItem],
  });

  return (
    <ModalItemsList
      topBlock={
        <MessageBlockWithButton
          message={isListEmpty ? 'Промокодов пока нет' : undefined}
          actionButtonConfig={
            hasPrivilegeToCreatePromoCode
              ? {
                  buttonText: 'Создать',
                  onButtonClick: () =>
                    updateModalStep(
                      AffiliateMediaItemsSteps.CreatePromoCodeBaseInfo,
                    ),
                }
              : undefined
          }
        />
      }
      itemsListContent={promoCodes?.edges.map(({ node }) => (
        <AffiliatePromoCodeItem
          promoCode={node}
          key={node.id}
          onDetailsClick={onDetailsClick}
        />
      ))}
      loading={loading}
      paginationConfig={{
        loadMore,
        hasNextPage: !!promoCodes?.pageInfo.hasNextPage,
      }}
    />
  );
};
