import { useContext } from 'react';
import { BlockContext } from 'ui/Block/store';
import Block from 'ui/Block';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import BlockTable from 'ui/Block/BlockTable';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { ColumnDef } from '@tanstack/react-table';
import { useGetCompanyMediaCampaigns } from './hooks/useGetCompanyMediaCampaigns';

interface Props<T extends object> {
  companyId: string;
  columns: Array<ColumnDef<T>>;
  isFullScreenTable?: boolean;
  shouldReturnToPrevPage?: boolean;
  routeToCustomPage?: string;
}

export const CompanyMediaCampaignsTable = <T extends { __typename: string }>({
  companyId,
  columns,
  isFullScreenTable,
  shouldReturnToPrevPage,
  routeToCustomPage,
}: Props<T>) => {
  const { state } = useContext(BlockContext);

  const {
    loadCompanyMediaCampaignsList,
    data,
    loading,
    error,
    hasNextPage,
    refetch,
    fetchMoreCallback,
    companyMediaCampaignsList,
  } = useGetCompanyMediaCampaigns<T>({ companyId, isFullScreenTable });

  useBlockComponentState({
    loadData: loadCompanyMediaCampaignsList,
    loading,
    error,
  });

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage,
    isFullScreenTable,
  });

  return (
    <Block
      emptyText="Медиакампаний нет"
      title="Медиакампании"
      id="companyMediaCampaignsList"
      headerContent={
        <ActionButton
          actionType={ButtonType.Update}
          hidden={!state.isOpened}
          disabled={state.isLoading}
          onClick={() => refetch()}
        />
      }
      isEmpty={isListEmpty(companyMediaCampaignsList)}
      shouldReturnToPrevPage={shouldReturnToPrevPage}
    >
      {hasList(companyMediaCampaignsList) && (
        <BlockTable
          columns={columns}
          data={companyMediaCampaignsList}
          isFullScreenTable={isFullScreenTable}
          routeToCustomPage={routeToCustomPage}
        />
      )}
    </Block>
  );
};
