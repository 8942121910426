import { TextOverflowMiddle } from 'ui/TextOverflowMiddle';
import { GetCompanyAffiliateReferralLinkMediaItems } from '../queries/generated/GetCompanyAffiliateReferralLinkMediaItems';

export const getCompanyAffiliateReferralLinksOptions = (
  referralLinks?: GetCompanyAffiliateReferralLinkMediaItems['company']['affiliate']['referralLinkMediaItems']['edges'],
  labelWidth?: number,
) =>
  referralLinks?.map(({ node }) => ({
    label: (
      <div style={{ width: `${labelWidth}px` }}>
        <TextOverflowMiddle text={node.referralLink} />
      </div>
    ),
    id: node.id,
  }));
