import { FC } from 'react';
import { AffiliateTypeName } from 'commonTypes';
import { generatePath } from 'react-router';
import { RouterLink } from 'ui/RouterLink';
import { RouterLinkSize } from 'ui/RouterLink/const';
import { Route } from 'src/router/routes.const';
import styles from './BlockSubHeaderAffiliateInfo.module.scss';

interface Props {
  affiliateType: AffiliateTypeName;
  name: string;
  id: string;
  email?: string | null;
}

export const BlockSubHeaderAffiliateInfo: FC<Props> = ({
  name,
  email,
  id,
  affiliateType,
}) => {
  const affiliateLabel =
    affiliateType === AffiliateTypeName.Partner ? 'Партнёр' : 'Компания';

  const route = generatePath(
    affiliateType === AffiliateTypeName.Partner ? Route.PARTNER : Route.COMPANY,
    {
      id,
    },
  );

  return (
    <div data-testid="blockSubHeaderAffiliateInfo" className={styles.subHeader}>
      <div
        className={styles.affiliateLabelWrapper}
        data-testid="affiliateLabel"
      >
        <span className={styles.label}>{affiliateLabel}</span>{' '}
        <RouterLink path={route} size={RouterLinkSize.Large}>
          {name}
        </RouterLink>
      </div>
      {email && (
        <div>
          <span className={styles.label}>Почта</span>{' '}
          <span className={styles.value}>{email}</span>
        </div>
      )}
    </div>
  );
};
