import { OwnerTypeName } from 'commonTypes';
import { MediaItemPromoCodeActivationData } from '../../../queries/generated/MediaItemPromoCodeActivationData';

export const getOwnerData = (
  referralLink: MediaItemPromoCodeActivationData['promoCodeMediaItem']['referralLink'],
) => {
  const owner = referralLink?.owner;

  if (!owner) {
    return null;
  }

  if (owner.__typename === OwnerTypeName.CompanyAffiliate) {
    return { name: owner.company.name, email: owner.company.email };
  }

  if (owner.__typename === OwnerTypeName.Partner) {
    return { name: owner.name, email: owner.partnerEmail };
  }

  return { name: owner.name, email: owner.companyEmail };
};
