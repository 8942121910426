import { FC } from 'react';
import Button, { ButtonTheme } from 'ui/Button';
import { PaymentStatus, Sum } from 'generatedGraphql';
import { UserPrivilege } from 'generatedUserPrivilege';
import { ConfirmationPopup } from 'commonComponents/ConfirmationPopup';
import { useOpenAcceptPaymentForm } from './hooks/useOpenAcceptPaymentForm';
import styles from './UpdatePaymentButtons.module.scss';
import { useDeclineWithdrawalPayment } from './hooks/useDeclineWithdrawalPayment';
import { useCheckIsUpdatePaymentButtonsVisible } from './hooks/useCheckIsUpdatePaymentButtonsVisible';

interface Props {
  paymentId: string;
  paymentStatus: PaymentStatus;
  paymentPaidSum?: Sum | null;
}

export const UpdatePaymentButtons: FC<Props> = ({
  paymentStatus,
  paymentId,
  paymentPaidSum,
}) => {
  const { handleDeclinePayment, loading } = useDeclineWithdrawalPayment();
  const { openAcceptPaymentForm } = useOpenAcceptPaymentForm();

  const { isAcceptButtonVisible, isDeclineButtonVisible } =
    useCheckIsUpdatePaymentButtonsVisible(paymentStatus);

  if (!isAcceptButtonVisible && !isDeclineButtonVisible) {
    return null;
  }

  return (
    <div className={styles.btnContainer}>
      {isAcceptButtonVisible && (
        <Button
          onClick={() =>
            openAcceptPaymentForm({
              paymentId,
              paymentPaidSum,
            })
          }
          disabled={loading}
          theme={ButtonTheme.Success}
        >
          Выполнить
        </Button>
      )}
      {isDeclineButtonVisible && (
        <ConfirmationPopup
          message="Вы точно хотите отклонить платеж?"
          action={() => handleDeclinePayment({ id: paymentId })}
          triggerElement={
            <Button
              className={styles.btn}
              isLoading={loading}
              theme={ButtonTheme.Important}
            >
              Отклонить
            </Button>
          }
          privilege={UserPrivilege.DeclinePayment}
        />
      )}
    </div>
  );
};
