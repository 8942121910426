export enum FieldName {
  MediaCampaignId = 'mediaCampaignId',
  Name = 'name',
  Code = 'code',
  TypeRewardTariff = 'typeRewardTariff',
  CPARewardTariffId = 'cpaRewardTariffId',
  RevShareRewardTariffId = 'revShareRewardTariffId',
  FallbackTariffId = 'fallbackTariffId',
  AffiliateType = 'affiliateType',
}

export const mapFieldNameToTitle = {
  [FieldName.MediaCampaignId]: 'Медиакампания',
  [FieldName.Name]: 'Название медиаэлемента',
  [FieldName.Code]: 'Реферальный код',
  [FieldName.TypeRewardTariff]: 'Модель оплаты',
  [FieldName.CPARewardTariffId]: 'Партнёрский тариф',
  [FieldName.FallbackTariffId]: 'Резервный тариф',
  [FieldName.RevShareRewardTariffId]: 'Партнёрский тариф',
};

export const NAME_PLACEHOLDER = 'Введите название';
export const MEDIA_CAMPAIGN_PLACEHOLDER = 'Выберите медиакампанию';
export const TYPE_REWARD_TARIFF_PLACEHOLDER = 'Выберите модель оплаты';
